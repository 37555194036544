import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function RecruitingLocationsContent({ page }) {
  const [ pageContent, setPageContent ] = useState({
    interviews: [],
  });

  useEffect(() => {
    if (page.children && page.children.length > 0) {
      let newPageContent = { ...pageContent };
      const corporateOfficeInterviews = page.children.find((child) => child.node.slug === "corporate-division-interviews")?.node;
      let corporateOfficeInterviewsItems = corporateOfficeInterviews.content.split("<ul>");

      corporateOfficeInterviewsItems.shift();
      let newFinalItems = [];
      corporateOfficeInterviewsItems.forEach((item, parentIndex) => {
        let newChildren = item.split("</li>");
        newChildren.pop();
        let newObject = {};

        newChildren.forEach((child, index) => {
          let newItem = child.replace(/<[^>]*>?/gm, "").trim();
          if (index === 0) newObject.location = newItem;
          else if (index === 1) newObject.period = newItem;
          else if (index === 2) newObject.date = newItem;
        });
        newFinalItems.push(newObject);
      });
      newPageContent.interviews = newFinalItems;
      setPageContent({ ...newPageContent });
    }
  }, [ page ]);

  if (!page) return null;

  return (
    <div>
      <h1 className="font-frutiger-light font-bold uppercase text-[#24292e] text-[30px]">{page.title}</h1>
      {page?.content && <div className="mt-4 post-content blue-arrows xl:mt-8" dangerouslySetInnerHTML={{ __html: page.content }} />}
      <article className="recruiting-table">
        <div id="main-office-block" data-type="main-office">
          <h2 className="uppercase font-semibold text-xl mb-2 mt-8">Corporate Office Interviews</h2>
          <div className="main-bg flex h-[62px] w-full items-center">
            <div className="grid w-full grid-cols-3 align-middle row">
              <div className="pl-1 pr-0 flex items-center pl-sm-2 pl-md-2 col-4 add-location">
                <div className="align-middle heading d-table-cell">
                  <i className="align-middle fa fa-map-marker-alt d-table-cell" aria-hidden="true"></i>
                  <span className="pl-0 align-middle pl-lg-1 d-table-cell">Main Office</span>
                </div>
              </div>
              <div className="pl-1 pr-0 align-middle pl-sm-2 pl-md-2 col-5 add-application-period">
                <div className="align-middle heading d-table-cell">
                  <i className="align-middle fa fa-file-alt d-table-cell" aria-hidden="true"></i>
                  <span className="pl-0 align-middle pl-lg-1 d-table-cell">Application Period</span>
                </div>
              </div>
              <div className="pl-1 pr-0 align-middle pl-sm-2 pl-md-2 col-3 add-date">
                <div className="align-middle heading d-table-cell">
                  <i className="align-middle fa fa-calendar-alt d-table-cell" aria-hidden="true"></i>
                  <span className="pl-0 align-middle pl-lg-1 d-table-cell">Next Available Interview</span>
                </div>
              </div>
            </div>
          </div>
          {pageContent.interviews.map((interview, index) => (
            <div key={index} className="hover-wrap relative w-full">
              <div className="click-to-apply">
                <Link to={`/online-application-form/?type=corporate-division&add-location=${interview.location}&add-application-period=${interview.period}&add-date=${interview.date}`} className="w-full">
                  <div className="apply-spacer w-full h-[40px]"></div>
                  <span>Click to Apply</span>
                </Link>
              </div>
              <ul className="flex w-full justify-between py-4 text-left trans-ease">
                <li className="add-location">{interview.location}</li>
                <li className="add-application-period">{interview.period}</li>
                <li className="add-date">{interview.date}</li>
              </ul>
            </div>
          ))}
        </div>
      </article>
    </div>
  );
}
