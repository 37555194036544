import React, { useEffect, useState } from "react";

import { useLocation, Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group"; // ES6

import Popover from "../../../components/Popover";
import AutoPlayVideo from "../../../components/shared/AutoPlayVideo";
import usePage from "../../../contexts/PageContext";

//TODO: need to make this clear background image like parents page navigation asap!!

const CustomLink = ({ to, label, title, color = "#0189E0", handleClick }) => {
  return (
    <Link onClick={() => handleClick()} className={`trans-ease-all arrow-link group flex items-center font-bold border-transparent hover:border-[${color}] border-b-2 cursor-pointer text-white absolute mt-2`} title={title} to={to}>
      <span className="mr-1">{label}</span>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke={color} className="blue w-3 h-3 mb-[.2rem]">
        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke={color} className="trans-ease-all opacity-0 group-hover:opacity-100 ml-[-6px] w-3 h-3 mb-[.2rem]">
        <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
      </svg>
    </Link>
  );
};

const MapLocation = ({ selected }) => {
  const hasVideo = selected.staffInformation.staffVideo?.node?.mediaItemUrl || null;

  return (
    <CSSTransition transitionname="list" transitionentertimeout={17500} transitionleavetimeout={3000} timeout={3000}>
      <div key={selected.slug} className={`${hasVideo ? "has-video " : ""}${selected ? "" : ""} map-location-messages col-12 col-md-9 col-lg-7 p-0 mejs-wmp max-w-[63%]`} data-src={selected.databaseId} data-slug={`#${selected.slug}`}>
        <div className={`message-block ${hasVideo ? "has-video" : ""}${selected ? "" : ""} fade-in-text trans-ease-all z-50 h-full`}>
          <ul className="location-messages">
            <li>
              {hasVideo && (
                <div className={`staff-message staff-video`}>
                  <div className="video-wrapper">
                    <div className="video-inner">
                      <AutoPlayVideo videoUrl={hasVideo} />
                      {/* <iframe allow="autoplay" loading="lazy" width="100%" height="100%" src={hasVideo} allowFullScreen></iframe> */}
                    </div>
                  </div>
                </div>
              )}
              {!hasVideo && selected.staffInformation.staffMessage && <div className={`staff-message font-georgia text-white italic`} dangerouslySetInnerHTML={{ __html: selected.staffInformation.staffMessage.replace(/(<([^>]+)>)/gi, "") }} />}
              {selected.staffInformation.regionLinkText && (
                <div className="py-4 link-title text-white font-frutiger-ce4 italic">
                  {/* <Link className="link-title text-white font-frutiger-ce4 italic" to={`/location/${selected.slug}`} title={`Learn more about ${selected.staffInformation.staffTitle}`}>
                    {selected.staffInformation.regionLinkText}
                  </Link> */}
                  <CustomLink color="#FFFFFF" to={`/location/${selected.slug}`} label={selected.staffInformation.regionLinkText} title={`Learn more about ${selected.staffInformation.staffTitle}`} />
                </div>
              )}

              <div className="staff-meta flex mt-12">
                {selected.staffInformation.staffImage && (
                  <div className="image-wrap">
                    <div className="staff-image mt-4 mr-4">
                      <img src={selected.staffInformation.staffImage?.node?.mediaItemUrl || ""} alt="" title={selected.staffInformation.staffTitle} className="map-location-image alignleft size-full wp-image-575" />
                    </div>
                  </div>
                )}
                <div className="info-wrap w-fit text-white">
                  <h1 className="staff-title font-bold">
                    {selected.staffInformation.staffTitle}
                    {" - "}
                    {selected.staffInformation.staffCountry}
                  </h1>
                  <h2 className="staff-location">{selected.staffInformation.staffLocation}</h2>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </CSSTransition>
  );
};

const MapMarker = ({ location, selected, updateSelected }) => {
  //TODO: when scrolled this changes to this pt-[139px] pl-[175px]
  return (
    <>
      <div
        id={`marker-${location.slug}`}
        className={`pt-[114px] pl-[176px] map-marker absolute ${selected ? "animated" : ""} ${selected?.slug === location.slug ? "selected " : ""}`}
        data-src={location.databaseId}
        data-slug={`#${location.slug}`}
        data-xcoord={location.staffInformation.xcoords}
        data-ycoord={location.staffInformation.ycoords}
        style={{ marginLeft: `${location.staffInformation.xcoords}px`, marginTop: `${location.staffInformation.ycoords}px` }}
      >
        <div className={`${selected?.slug === location.slug ? "animated current" : ""} map-marker-big absolute big-marker-${location.slug}`} />
        <Popover
          trigger="hover"
          className="trans-ease-slow"
          content={
            <div onClick={() => updateSelected(location.slug)} className={`map-location location-${location.slug}`} data-slug={`#${location.slug}`}>
              <div className="map-location-thumb h-full w-full rounded-md border-2 border-white" style={{ background: `url(${location.staffInformation.locationImage?.node?.mediaItemUrl || ""}) no-repeat`, backgroundSize: "cover" }}></div>
              <div className="map-location-title absolute text-center text-white rounded-b-md">{location.title}</div>
            </div>
          }
        >
          <Link to={`#${location.slug}`}>
            <div onClick={() => updateSelected(location.slug)} className={`${selected?.slug === location.slug ? "hidden" : ""} map-marker-small absolute small-marker-${location.slug}`}></div>
          </Link>{" "}
        </Popover>
      </div>
      <div className={`hidden map-message message-${location.databaseId}${selected?.slug === location.slug ? " animated " : ""}`}>{location.title}</div>
    </>
  );
};

export default function TestimonialsFromOurStaffContent({ locations }) {
  const { siteData, setSiteData } = usePage();
  const location = useLocation();

  const [ selectedIndexes, setSelectedIndexes ] = useState({});
  const [ selected, setSelected ] = useState();
  const [ selectedHash, setSelectedHash ] = useState(location.hash);

  const [ validLocations, setValidLocations ] = useState([]);

  const [ screenSize, setScreenSize ] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleClick = (link) => {
    const prevLocations = siteData.prevLocation.includes("/") ? location.pathname.split("/").filter((item) => item !== "") : location.pathname;

    let newSiteData = { ...siteData };
    console.log("handleClick");
    if (prevLocations[0] !== link) {
      newSiteData.curPanelBackground = null;
      newSiteData.isLoading = true;
    } else {
      newSiteData.isLoading = false;
    }
    debugger;
    setSiteData({ ...newSiteData });
  };

  useEffect(() => {
    // Debounce timer variable
    let debounceTimer;

    // Function to update screen size with debounce
    const updateScreenSize = () => {
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => {
        setScreenSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 300); // 300ms debounce time, adjust as needed
    };

    // Add event listener for resize
    window.addEventListener("resize", updateScreenSize);

    // Cleanup event listener on component unmount
    return () => {
      clearTimeout(debounceTimer); // Clear debounce timer on unmount
      window.removeEventListener("resize", updateScreenSize);
    };
  }, []);

  useEffect(() => {
    if (screenSize.width < 768) {
      console.log("Small screen - mobile layout");
      // Run your function for small screens
    } else {
      console.log("Large screen - desktop layout");
      // Run your function for large screens
    }
  }, [ screenSize ]);

  useEffect(() => {
    if (locations && locations.length) {
      const newLocations = [];

      locations.forEach((location, index) => {
        if (location.node.staffInformation?.staffImage || location.node.staffInformation?.staffVideo) {
          newLocations.push(location.node);
        }
      });

      if (selectedHash) {
        const newLocation = newLocations.find((location) => location.slug === selectedHash.replace("#", ""));
        setSelected(newLocation);

        const newIndex = newLocations.indexOf(newLocation);
        const prevIndex = newIndex === 0 ? newLocations.length - 1 : newIndex - 1;
        const nextIndex = newIndex === newLocations.length - 1 ? 0 : newIndex + 1;

        const newIndexes = {
          index: newIndex,
          prevIndex: newLocations[prevIndex].slug,
          nextIndex: newLocations[nextIndex].slug,
        };
        setSelectedIndexes(newIndexes);
      }
      for (const location of newLocations) {
        const imageElement = new Image();
        imageElement.src = location.staffInformation.locationImage.node.mediaItemUrl;
      }

      setValidLocations([ ...newLocations ]);

      newLocations.forEach((location, index) => {
        const delay = Number(index * 25);

        setTimeout(() => {
          const marker = document.getElementById(`marker-${location.slug}`);
          if (marker) marker.classList.add("animated");
        }, delay);
      });
    }
  }, [ locations ]);

  const updateSelected = (newHash) => {
    setSelected(null);
    const newLocation = validLocations.find((location) => location.slug === newHash);
    setSelected(newLocation);
    window.location.hash = newHash;

    const newIndex = validLocations.indexOf(newLocation);
    const prevIndex = newIndex === 0 ? validLocations.length - 1 : newIndex - 1;
    const nextIndex = newIndex === validLocations.length - 1 ? 0 : newIndex + 1;

    const newIndexes = {
      index: newIndex,
      prevIndex: validLocations[prevIndex].slug,
      nextIndex: validLocations[nextIndex].slug,
    };

    setSelectedIndexes(newIndexes);
  };

  return (
    <section id="testimonials-from-our-staff" className="min-h-screen p-4 bg-transparent z-50 trans-ease-all">
      {selected && selectedIndexes && (
        <div id="map-controls" className="map-sides map-left animated w-[24px] xl:w-[28px]">
          <div className="opacity-0 invisible map-quote animated w-[24px] h-[18px] absolute" style={{ background: "url('/images/map-quote.png') top center no-repeat", backgroundSize: "18px" }} />
          <span
            onClick={(e) => {
              e.stopPropagation();
              updateSelected(selectedIndexes.nextIndex);
            }}
            style={{ background: "url('/images/map-right-arrow.png') top center no-repeat", backgroundSize: "18px" }}
            data-src="297"
            className={`mt-[32px] opacity-0 invisible absolute trans-ease-all hover:scale-150 animated w-[24px] h-[22px] `}
            href="#"
            id="map-right-arrow"
            title={`Next Location: ${validLocations.find((item) => item.slug === selectedIndexes.nextIndex).title}`}
            data-slug={selectedIndexes.nextIndex}
          >
            &nbsp;
          </span>
          <span
            onClick={(e) => {
              e.stopPropagation();
              updateSelected(selectedIndexes.prevIndex);
            }}
            style={{ background: "url('/images/map-left-arrow.png') top center no-repeat", backgroundSize: "18px" }}
            data-src="560"
            className={`mt-[58px] opacity-0 invisible absolute trans-ease-all hover:scale-150 animated w-[24px] h-[22px]`}
            href="#"
            id="map-left-arrow"
            title={`Previous Location: ${validLocations.find((item) => item.slug === selectedIndexes.prevIndex).title}`}
            data-slug={selectedIndexes.prevIndex}
          >
            &nbsp;
          </span>
        </div>
      )}
      <div id="map-messages-block" className={`animated trans-ease-all opacity-0 fade-out-text col relative min-h-[646px] ml-10`}>
        {!location.hash && <div id="map-messages-title" className={`absolute ${!selectedHash && !selected ? "opacity-100 fade-in-text cursor-pointer" : "opacity-0 fade-out-text"}  z-50 w-[540px] h-[54px] mt-[80px]`} style={{ background: "url('/images/messages-from-our-staff-new.png') no-repeat" }} />}
        {selected && <MapLocation selected={selected} />}
        {validLocations.map((location, index) => {
          return (
            <div key={location.slug} className="hidden md:block">
              <MapMarker location={location} selected={selected} updateSelected={updateSelected} />
            </div>
          );
        })}
      </div>
      <div className="row">
        <div className="container">
          <div id="career-opportunities-white" className="ml-[66%] -mt-22 md:-mt-24 lg:-mt-28 mb-8 career-opportunities-wrapper position-relative">
            <CustomLink handleClick={handleClick} color="#FFFFFF" to="/career-opportunities/" label="LEARN MORE" title="Career Opportunities" />
          </div>
        </div>
      </div>
    </section>
  );
}
