import React from "react";
import { BrowserRouter as Router, Route, Link, Routes, useParams } from "react-router-dom";
import { ApolloProvider } from "@apollo/client/react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import client from "./lib/apollo";

import { PageProvider } from "./contexts/PageContext";
import MainWrapper from "./components/shared/layouts/MainWrapper";
import HomeLoader from "./pages/HomeLoader";
import CorporateDivision from "./pages/CorporateDivision";
import RecruitingLocations from "./pages/RecruitingLocations";
import DefaultPage from "./pages/DefaultPage";
import FAQS from "./pages/FAQS";
import CareerOpportunities from "./pages/CareerOpportunities";
import SideNavPage from "./pages/SideNavPage";
import TestimonialsFromOurStaff from "./pages/TestimonialsFromOurStaff";
import LocationPage from "./pages/LocationPage";
import OnlineApplication from "./pages/OnlineApplication";

function App(props) {
  //let { slug, catslug } = useParams();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <ApolloProvider client={client}>
        <PageProvider>
          <MainWrapper>
            <Routes>
              <Route exact path="/" element={<HomeLoader {...props} />} />
              <Route exact path="/search/:search" element={<HomeLoader {...props} />} />
              <Route exact path="/english-education" element={<SideNavPage {...props} />} />
              <Route exact path="/english-education/:slug" element={<SideNavPage {...props} />} />
              <Route exact path="/life-at-aeon" element={<SideNavPage {...props} />} />
              <Route exact path="/life-at-aeon/:slug" element={<SideNavPage {...props} />} />
              <Route exact path="/life-in-japan" element={<SideNavPage {...props} />} />
              <Route exact path="/life-in-japan/:slug" element={<SideNavPage {...props} />} />
              <Route exact path="/location" element={<LocationPage sidebar="small" {...props} />} />
              <Route exact path="/location/:slug" element={<LocationPage sidebar="small" {...props} />} />
              <Route exact path="/application-requirements" element={<SideNavPage sidebar="small" {...props} />} />
              <Route exact path="/application-requirements/:slug" element={<SideNavPage sidebar="small" {...props} />} />
              <Route exact path="/testimonials-from-our-staff" element={<TestimonialsFromOurStaff {...props} />} />
              <Route exact path="/career-opportunities" element={<CareerOpportunities {...props} />} />
              <Route exact path="/frequently-asked-questions" element={<FAQS {...props} />} />
              <Route exact path="/recruitment-information-for-japanese-citizens" element={<DefaultPage {...props} />} />
              <Route exact path="/about-aeon" element={<SideNavPage {...props} />} />
              <Route exact path="/about-aeon/:slug" element={<SideNavPage {...props} />} />
              <Route exact path="/corporate-division" element={<CorporateDivision {...props} />} />
              <Route exact path="/recruiting-locations" element={<RecruitingLocations {...props} />} />
              <Route exact path="/online-application-form" element={<OnlineApplication {...props} />} />
            </Routes>
          </MainWrapper>
        </PageProvider>
      </ApolloProvider>
    </LocalizationProvider>
  );
}

export default App;
