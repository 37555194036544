import React, { useEffect, useRef } from "react";

const AutoPlayVideo = ({ videoUrl }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const video = videoRef.current;

    // Try to play the video with sound
    const playVideo = async () => {
      try {
        await video.play();
      } catch (error) {
        // If autoplay fails, you can handle it here, e.g., by prompting the user
        console.log("Autoplay failed:", error);
      }
    };

    playVideo();
  }, []);

  return <video ref={videoRef} width="100%" height="100%" controls src={videoUrl} autoPlay />;
};

export default AutoPlayVideo;
