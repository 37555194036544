import React from "react";
import { Link } from "react-router-dom";

import { useScrollPosition } from "../../hooks/useScrollPosition";

export default function BackgroundImagePanel({ url }) {
  const [ scrollPosition ] = useScrollPosition();

  // useEffect(() => {
  //   if (scrollPosition) console.log('scrollPosition', scrollPosition)
  // }, [scrollPosition])

  return (
    <div className="banner-wrapper absolute w-full h-[238px] md:h-[312px] lg:h-[408px] -mt-[6rem]">
      <div className="banner m-auto w-full h-full fade-edge-left max-w-[1996px] relative">
        <div className="banner m-auto w-full h-full fade-edge-right max-w-[1996px] relative">
          <div className="banner-image w-full max-w-[1996px] mx-auto h-full absolute bg-[length:800px] md:bg-[length:960px] lg:bg-[length:1200px] xl:bg-[length:1996px]" id="" data-type="background" data-speed="14" style={{ background: `url('${url}') 50% -${scrollPosition * 0.075 || 0}px no-repeat fixed` }}></div>
        </div>
      </div>
    </div>
  );
}
