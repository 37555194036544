import React from "react";
import { Link } from "react-router-dom";
import Locations from "../../Locations";

export default function DefaultSideContent({ loading, locations }) {
  return (
    <div className={`${loading ? "opacity-0 " : ""}mx-2`}>
      <div className="side-blue text-center mb-4 rounded-md pb-2 custom-shadow">
        <div id="interviewing-year-round" className="position-relative m-auto tighten trans-ease-all px-1 md:pb-1 md:px-2 lg:pb-2 lg:px-3">
          <h5 className="white-title text-center pt-3">
            Interviewing
            <br />
            Year Round Worldwide
          </h5>
          <div className="mt-2 mb-3 w-36 border-b border-black mx-auto" />
          <Link title="Submit Application" to="/recruiting-locations" className="group flex items-center justify-center font-bold drop-shadow hover:drop-shadow-lg custom-shadow mx-auto trans-ease-all rounded-md mt-3 text-sm btn btn-grey">
            <span className="max-xl:text-[.75rem]">SUBMIT APPLICATION</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="#000000" className="blue w-3 h-3 mb-[.1rem]">
              <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="#000000" className="trans-ease-all opacity-0 w-0 group-hover:w-3 group-hover:opacity-100 ml-[-6px]  h-3 mb-[.1rem]">
              <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </Link>
        </div>
      </div>
      <div className="side-grey text-center mb-4 rounded-md pb-2 custom-shadow">
        <div id="frequently-asked-questions" className="position-relative m-auto tighten trans-ease-all px-1 md:pb-1 md:px-2 lg:pb-2 lg:px-3">
          <h5 className="black-title black-line text-center pt-3">
            Frequently
            <br />
            Asked Questions
          </h5>
          <div className="mt-2 mb-3 w-36 border-b border-black mx-auto" />
          <Link title="Get the Frequently Asked Questions" to="/frequently-asked-questions" className="group flex items-center justify-center font-bold drop-shadow hover:drop-shadow-lg  custom-shadow mx-auto trans-ease-all rounded-md mt-3 text-sm btn btn-blue">
            <span className="max-xl:text-[.75rem]">GET THE FAQ'S</span>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="#ffffff" className="blue w-3 h-3 mb-[.1rem]">
              <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="#ffffff" className="trans-ease-all opacity-0 w-0 group-hover:w-3 group-hover:opacity-100 ml-[-6px]  h-3 mb-[.1rem]">
              <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
            </svg>
          </Link>
        </div>
      </div>
      <Locations locations={locations} position="side" />
    </div>
  );
}
