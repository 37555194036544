import React, { useEffect, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";
import usePage from "../contexts/PageContext";

import { gql, useQuery } from "@apollo/client";
import { ADD_LOCATIONS_WITH_CONTENT } from "../lib/graphql";

import MetaHead from "../components/shared/MetaHead";

import BackgroundImagePanel from "../components/shared/BackgroundImagePanel";
import SearchSidebar from "../components/shared/layouts/SearchSidebar";

export default function SearchPage({ searchParam }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  // let { search } = useParams();
  // console.log("search searchParam page ", search, searchParam);

  const { siteData, setSiteData } = usePage();

  const [ isLoading, setIsLoading ] = useState(true);
  const [ searchData, setSearchData ] = useState([]);
  const [ visibleItems, setVisibleItems ] = useState({});

  let pageParam = queryParams.get("page");

  const [ pagination, setPagination ] = useState({
    page: pageParam || 1,
    total: 0,
    totalPages: 1,
    pageSize: 10,
    type: "",
  });

  const seoObject = {
    modified: "",
    link: "https://dev2.aeonet.com/search",
    seo: {
      __typename: "PostTypeSEO",
      metaKeywords: "",
      metaDesc: "AEON Search Results.",
      canonical: "",
      title: `Search Results: ${searchParam} | AEON`,
      opengraphUrl: "https://dev2.aeonet.com/search/",
      opengraphTitle: `Search Results: ${searchParam} | AEON`,
      opengraphType: "article",
      opengraphAuthor: "",
      opengraphDescription: "AEON Search Results.",
      opengraphImage: {
        __typename: "MediaItem",
        mediaItemUrl: "https://www.aeonet.com/wp-content/uploads/2012/08/1.Shibuya-e13498476426571.jpg",
        mediaDetails: {
          __typename: "MediaDetails",
          width: 2000,
          height: 318,
        },
        mimeType: "image/jpeg",
      },
      opengraphSiteName: "AEON",
      readingTime: 1,
    },
  };

  const parser = new DOMParser();
  const decodeString = (str) => parser.parseFromString(str, "text/html").body.textContent;

  let curQueries = ADD_LOCATIONS_WITH_CONTENT;
  const GET_PAGE_DATA = gql`
    query getPageData {    
      ${curQueries}
    }
  `;

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    skip: siteData?.locations.length > 0,
  });

  useEffect(() => {
    //console.log("siteData", siteData);

    if (pageData && pageData?.locations) {
      //console.log("pageData", pageData);
      let newSiteData = { ...siteData };

      setSiteData({
        ...newSiteData,
        locations: [ ...pageData.locations.edges ],
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  //   Brief usage examples

  // ?keyword=query
  // ?keyword=query&per_page=5
  // ?keyword=query&per_page=5&page=2
  // Define post type:

  // ?keyword=query&per_page=5&page=2&type=post
  // Filter by taxonomy/taxonomies:

  // ?keyword=test&tax_query[0][taxonomy]=category&tax_query[0][field]=id&tax_query[0][terms]=3
  // ?keyword=test&tax_query[relation]=AND&tax_query[0][taxonomy]=category&tax_query[0][field]=id&tax_query[0][terms]=3&tax_query[1][taxonomy]=category&tax_query[1][field]=id&tax_query[1][terms]=2
  // Exclude category via taxonomies:

  // ?keyword=test&tax_query[0][taxonomy]=category&tax_query[0][field]=id&tax_query[0][terms]=3&tax_query[0][operator]=NOT IN
  // For multilingual websites (WPML & Polylang):

  // ?keyword=query&lng=en

  // Results in order:
  // ?keyword=test&type=post&orderby=modified&order=DESC

  // ?keyword=test&type=post&orderby=modified&order=ASC
  // ?keyword=test&type=post&meta_key=some_key&orderby=meta_value|meta_value_num&order=ASC

  const fetchData = async (page = pagination.page) => {
    setIsLoading(true);

    fetch(`https://dev2.aeonet.com/wp-json/relevanssi/v1/search?keyword=${searchParam}&per_page=${pagination.pageSize}&page=${page}`)
    .then((response) => {
      const total = response.headers.get("X-WP-Total");
      const totalPages = response.headers.get("X-WP-TotalPages");
      const type = response.headers.get("X-WP-Type");

      setPagination({
        pageSize: 10,
        page,
        total,
        totalPages,
        type,
      });

      if (page > 1) {
        queryParams.set("page", page);
      } else {
        queryParams.delete("page");
      }
      window.history.pushState({}, "", "?" + queryParams.toString());
      return response.json();
    })
    .then((data) => {
      setSearchData([ ...data ]);

      setVisibleItems({});

      data.forEach((item, index) => {
        setTimeout(() => {
          setVisibleItems((prev) => ({
            ...prev,
            [item.id]: true,
          }));
          if (index === data.length - 1) {
            setIsLoading(false);
          }
        }, index * 100);
      });
    });
  };

  useEffect(() => {
    if (searchParam) {
      //console.log("search", search);
      fetchData();
    }
  }, [ searchParam ]);

  const getResultsCount = () => {
    // console.log("pagination", pagination);
    const curCount = Number(pagination.page) * Number(pagination.pageSize);
    const startCount = Number(curCount) - Number(pagination.pageSize) + 1;
    const endCount = Number(pagination.total) < Number(curCount) ? pagination.total : Number(curCount);

    return (
      <p className="text-sm text-gray-700">
        <span className="font-medium">
          {!isLoading && (
            <span className="text-sm text-gray-700">
              Showing <span className="font-medium">{startCount}</span> to <span className="font-medium">{endCount}</span> of <span className="font-medium">{pagination.total}</span> results
            </span>
          )}
        </span>
      </p>
    );
  };

  const loadPage = (page) => {
    setPagination({
      ...pagination,
      page,
    });
    fetchData(page);
  };
  if (!siteData) return null;

  return (
    <div id="search-wrapper" className="bg-[#fcfcfc] min-h-screen">
      {seoObject && <MetaHead seoObject={seoObject} />}
      <BackgroundImagePanel url="https://www.aeonet.com/wp-content/uploads/2012/08/1.Shibuya-e13498476426571.jpg" />
      <main id="main-container" className="w-full page-container z-10 mt-[224px] pt-4 relative  bg-[#fcfcfc]  grid grid-cols-4 max-w-7xl lg:max-w-[1400px] xl:max-w-[1600px] m-auto mb-4 gap-[1px]">
        <div id="loading" className="col-span-4 relative w-full flex items-center justify-center top-0 animate-in duration-500 fade-in fade-out ease-in-out trans-ease-all">
          {/* <div className={`${!siteData.isLoading && "h-0 hidden"} relative trans-ease-all`}>
            <div className="absolute animate-in duration-500 fade-in fade-out ease-in-out trans-ease-slow top-[100px] loading"></div>
          </div> */}
        </div>
        <div className="side-container hidden lg:block -mt-[81px]">
          <SearchSidebar size="full" />
        </div>
        <section className="col-span-4 min-[1024px]:col-span-3 lg:col-span-3 mx-auto w-full -mt-[81px] blue-arrows">
          <article className="mb-4 rounded-sm  p-8 pt-12 bg-[#fcfcfc] z-50 min-h-full transe-ease-all">
            <h1 className="font-frutiger-light uppercase font-bold text-[#24292e] mb-8">Search Results for: {searchParam}</h1>
            {searchData &&
              searchData.map((item) => (
                <section key={item.id} id={`post-${item.id}`} className={`transition-opacity duration-500 ease-in-out ${visibleItems[item.id] ? "opacity-100" : "opacity-0"} border-t border-gray-200`}>
                  <header className="entry-header">
                    <h2 className="mt-4 entry-title font-bold">
                      <Link to={item.link} rel="bookmark">
                        {decodeString(item.title.rendered)}
                      </Link>
                    </h2>
                  </header>

                  <div className="entry-summary">
                    <span className="excerpt_part">
                      <Link to={item.link} rel="bookmark">
                        <p className="mb-4 mt-1 xl:mt-2" dangerouslySetInnerHTML={{ __html: decodeString(item.excerpt.rendered) }} />
                      </Link>
                    </span>
                  </div>
                </section>
              ))}
            {!isLoading && (
              <nav className="transe-ease-slow flex items-center justify-between py-3 border-t border-gray-200" aria-label="Pagination">
                <div className="hidden sm:block">{getResultsCount()}</div>
                <div className="flex flex-1 justify-between sm:justify-end">
                  <button
                    onClick={() => loadPage(Number(pagination.page) - 1)}
                    disabled={isLoading || pagination.page == 1}
                    className={`btn btn-blue ${isLoading || pagination.page === 1 ? "opacity-50 cursor-default" : "hover:bg-gray-50 focus-visible:outline-offset-0"} relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300`}
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => loadPage(Number(pagination.page) + 1)}
                    disabled={isLoading || pagination.page == pagination.totalPages}
                    className={`btn btn-blue ${isLoading || pagination.page == pagination.totalPages ? "opacity-50 cursor-default" : "hover:bg-gray-50 focus-visible:outline-offset-0"} relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 `}
                  >
                    Next
                  </button>
                </div>
              </nav>
            )}
          </article>
        </section>
      </main>
      <div className={`${isLoading ? "hidden " : ""}side-container block lg:hidden px-2 max-w-[540px] min-[768px]:max-w-[720px] m-auto`}>
        <SearchSidebar size="full" />
      </div>
    </div>
  );
}
