import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { TERipple } from "tw-elements-react";

import MetaHead from "../components/shared/MetaHead";
import BackgroundImagePanel from "../components/shared/BackgroundImagePanel";
import OnlineApplicationContent from "../components/OnlineApplicationContent";
import CorporateOnlineApplicationContent from "../components/CorporateOnlineApplicationContent";
import SideOnlineApplication from "../components/shared/layouts/SideOnlineApplication";
import CorporateSideOnlineApplication from "../components/shared/layouts/CorporateSideOnlineApplication";
import ModalDefault3 from "../components/shared/ModalDefault3";

import usePage from "../contexts/PageContext";
import { gql, useQuery } from "@apollo/client";
import { ADD_PAGES_PLAIN_CHILDREN } from "../lib/graphql";

export default function OnlineApplication(props) {
  let navigate = useNavigate();

  const { siteData, setSiteData } = usePage();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const interviewType = queryParams.get("type");
  const isCorporate = interviewType === "corporate-division";
  const isWorldWide = interviewType === "worldwide-interviews";

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/") ? location.pathname.split("/").filter((item) => item !== "") : location.pathname;
  const slugId = curLocations[curLocations.length - 1];

  let curQueries = ADD_PAGES_PLAIN_CHILDREN;
  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: { id: slugId },
    skip: siteData?.pages[curLocation],
  });

  const [ privacyAccepted, setPrivacyAccepted ] = useState(false);
  const [ showModal, setShowModal ] = useState(true);

  const options = {
    backdrop: true,
    position: "top",
  };

  function openModal() {
    setShowModal(true);
  }
  const onClose = () => {
    if (!showModal && !privacyAccepted) interviewType !== "corporate-division" ? navigate("/recruiting-locations") : navigate("/corporate-division");
    setShowModal(false);
  };
  function handlePrivacy() {
    setPrivacyAccepted(true);
    setShowModal(false);
  }
  useEffect(() => {
    if (pageData && pageData.pages) {
      let newSiteData = { ...siteData };

      let newPageContent = null;
      let newPageError = null;
      const successObject = {};

      const newPage = pageData.pages?.nodes[0] || [];
      const featuredImage = newPage?.featuredImage?.node || null;
      const curChildren = newPage.children?.edges || null;

      const splitContent = newPage.content.split(/<div class="widget-wrap">/);
      splitContent.shift();
      newPageContent = interviewType !== "corporate-division" ? splitContent[0] : splitContent[1];

      if (curChildren) newSiteData.curChildren = curChildren;
      if (featuredImage?.mediaItemUrl) newSiteData.curPanelBackground = featuredImage.mediaItemUrl;

      if (curChildren) {
        const successType = isCorporate ? "corporate-success" : isWorldWide ? "japan-success" : "success";
        successObject.success = curChildren.find((child) => child.node.slug === "success")?.node?.content;
        successObject.corporateSuccess = curChildren.find((child) => child.node.slug === "corporate-success")?.node?.content;
        successObject.japanSuccess = curChildren.find((child) => child.node.slug === "japan-success")?.node?.content;
        newPageError = "<span>Error</span>";
      }

      newSiteData.pages = {
        ...siteData.pages,
        [curLocation]: {
          ...newPage,
          newPageContent: newPageContent,
          successObject: successObject,
          newPageError: newPageError,
          children: curChildren,
          featuredImage: featuredImage,
        },
      };

      newSiteData.prevPage = null;
      newSiteData.nextPage = null;

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: location.pathname,
        curLocations: curLocations,
        curChildren: curChildren,
        curSlug: slugId,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage = siteData?.pages[curLocation];

  useEffect(() => {
    if (curPage) {
      let newSiteData = { ...siteData };

      const featuredImage = curPage?.featuredImage || null;
      if (featuredImage?.mediaItemUrl) newSiteData.curPanelBackground = featuredImage.mediaItemUrl;

      setSiteData({
        ...newSiteData,
        curParent: null, //curPage
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curChildren: curPage?.children || null,
        curSlug: slugId,
        isLoading: false,
      });
      //debugger;
      if (siteData && !privacyAccepted) {
        openModal();
      }
    }
  }, [ curPage ]);

  if (!siteData) return null;

  const privacyPopup = siteData.curChildren.find((child) => child.node.slug === "aeon-corporations-data-collection-and-privacy-policy")?.node;

  return (
    <div className="bg-[#fcfcfc] min-h-screen trans-ease-all">
      {curPage && <MetaHead seoObject={curPage} />}
      <BackgroundImagePanel url={siteData.curPanelBackground} />
      <main id="main-container" className="min-h-screen w-full z-10 mt-[224px] relative grid grid-cols-4 min-[576px]:max-w-[540px] min-[768px]:max-w-[720px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto mb-4  trans-ease-all">
        <section className="col-span-4 min-[1024px]:col-span-3 lg:col-span-3 mx-auto w-full -mt-[81px] blue-arrows">
          {!isCorporate && <OnlineApplicationContent page={curPage} siteData={siteData} />}
          {isCorporate && <CorporateOnlineApplicationContent page={curPage} siteData={siteData} />}
        </section>
        <div className="hidden lg:block h-full w-full max-w-[300px] min-[1600px]:max-w-[350px] ml-[1px]">
          {isCorporate && <CorporateSideOnlineApplication type={interviewType} page={curPage} parent={siteData.curParent} children={siteData.curChildren} />}
          {!isCorporate && <SideOnlineApplication type={interviewType} page={curPage} parent={siteData.curParent} children={siteData.curChildren} />}
        </div>
      </main>
      <div className="px-8 pb-4 block lg:hidden max-w-[540px] min-[768px]:max-w-[720px] m-auto">
        {isCorporate && <CorporateSideOnlineApplication mobileBottom={true} type={interviewType} page={curPage} parent={siteData.curParent} children={siteData.curChildren} />}
        {!isCorporate && <SideOnlineApplication mobileBottom={true} type={interviewType} page={curPage} parent={siteData.curParent} children={siteData.curChildren} />}
      </div>

      <ModalDefault3 options={options} title="AEON Corporation's Data Collection and Privacy Policy" location="top-0" size="xl" showModal={showModal && !siteData.isLoading} setShowModal={setShowModal} callBack={handlePrivacy} onHide={onClose} addClasses="!bg-[#4ab8fd] mt-0 sm:mt-[16px] md:mt-[32px] lg:mt-[64px] text-neutral-100 mx-auto border border-neutral-100 max-w-[1600px]">
        <>
          {privacyPopup?.content && <div dangerouslySetInnerHTML={{ __html: privacyPopup?.content }} />}
          <input id="acceptance" name="acceptance" type="checkbox" className="peer" />
          <label htmlFor="acceptance" className="pl-2 pr-4 cursor-pointer">
            I understand and agree to AEON Corporation's Data Collection and Privacy Policy
          </label>
          <div className="h-0 mt-2 opacity-0 peer-checked:h-auto peer-checked:opacity-100 float-right trans-ease-all overflow-hidden">
            <TERipple rippleColor="light">
              <button
                type="button"
                onClick={() => handlePrivacy()}
                className="ml-1 inline-block rounded bg-primary px-6 pb-2 pt-2.5 text-xs font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
              >
                Accept
              </button>
            </TERipple>
          </div>
        </>
      </ModalDefault3>
    </div>
  );
}
