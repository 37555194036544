import { ApolloClient, InMemoryCache } from "@apollo/client";

const client = new ApolloClient({
  uri: "https://dev2.aeonet.com/graphql",
  //uri: "https://www.spcinc.com/dev/graphql",
  // uri: "https://democontenthub.wpengine.com/graphql",
  cache: new InMemoryCache(),
});

export default client;
