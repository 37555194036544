import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import HomePageContent from "../components/HomePageContent";
import CarouselContent3 from "../components/shared/CarouselContent3";

import usePage from "../contexts/PageContext";
import { gql, useQuery } from "@apollo/client";
import { ADD_PAGES, ADD_LOCATIONS_WITH_CONTENT, ADD_SLIDES } from "../lib/graphql";

export default function HomePage(props) {
  const { siteData, setSiteData } = usePage();
  const [ pageIsReady, setPageIsReady ] = useState(true);
  const location = useLocation();
  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/") ? location.pathname.split("/").filter((item) => item !== "") : location.pathname;

  let curQueries = ADD_PAGES + ADD_SLIDES + ADD_LOCATIONS_WITH_CONTENT;

  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: { id: "home-page" },
    skip: siteData?.pages[curLocation] && siteData?.locations,
  });

  useEffect(() => {
    if (pageData && pageData.pages) {
      console.log("pageData pageData", pageData);
      let newSiteData = { ...siteData };
      const newPage = pageData.pages?.nodes[0] || [];
      const locations = pageData?.locations?.edges || [];
      const slides = pageData?.slides?.edges || [];

      newSiteData.locations = locations;
      newSiteData.pages = {
        ...siteData.pages,
        "home-page": { ...newPage, slides: slides },
      };

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: location.pathname,
        curLocations: curLocations,
        curChildren: [],
        curParent: null,
        curSlug: "home-page",
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage = siteData.pages["home-page"];

  useEffect(() => {
    console.log("curPage", curPage);
    if (curPage) {
      setPageIsReady(true);
    }
  }, [ curPage ]);

  if (!siteData) return null;
  if (curPage && !curPage?.seo) {
    console.log("missing SEO!!! curPage", curPage);
  }

  return (
    <>
      <div id="home-slideshow" className="relative container-fluid mx-auto trans-ease-slow">
        <>
          <div className="relative top-0 opacity-0 -mt-[6rem]">
            {/* <img src={curPage.slides[0]?.node?.featuredImage?.node?.guid} height={curPage.slides[0]?.node?.featuredImage?.node?.mediaDetails.sizes[3]?.height} width={curPage.slides[0]?.node?.featuredImage?.node?.mediaDetails.sizes[3]?.width} className="block w-full" alt="..." /> */}
            <img src={"http://www.aeonet.com/wp-content/uploads/2019/02/AEON-Teach-English-in-Japan-Innovator-in-English-Language-Education.jpg"} className="block w-full" alt="..." />

            {/* <img src={curPage?.featuredImage?.node?.mediaItemUrl} className="block w-full" alt="..." /> */}
          </div>
          {curPage?.slides && <CarouselContent3 slides={curPage.slides} />}
        </>
      </div>

      <main id="main-container" className="h-full w-full mx-auto pt-4 mt-6 z-10 min-h-screen relative bg-[#fcfcfc] max-w-[1600px] px-6">
        {curPage && pageIsReady && <HomePageContent page={curPage} />}
      </main>
    </>
  );
}
