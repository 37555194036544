import React from "react";
import { Link } from "react-router-dom";

const CustomLink = ({ to, label, title, direction }) => {
  const StaticArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="#33a1e7" className={`blue w-3 h-3 ${direction === "left" ? "rotate-180 mr-[1.2px]" : "ml-[1px]"}`}>
      <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  );

  const HoverArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="#33a1e7" className={`trans-ease-all opacity-0 group-hover:opacity-100 w-3 h-3 ${direction === "left" ? "rotate-180 mr-[-6px] " : "ml-[-6px]"}`}>
      <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  );

  return (
    <div className={`button-wrapper w-fit ${direction === "left" ? "ml-8" : "mr-8"}`}>
      <Link className="trans-ease-all arrow-link group flex items-center uppercase text-sm font-semibold  hover:text-[#0056b3]  blue border-transparent hover:border-[#33a1e7] border-b-2" title={title} to={to}>
        {direction === "right" && (
          <>
            <span className="">{label}</span>
            <StaticArrow />
            <HoverArrow />
          </>
        )}
        {direction === "left" && (
          <>
            <HoverArrow />
            <StaticArrow />
            <span className="">{label}</span>
          </>
        )}{" "}
      </Link>
    </div>
  );
};

export default function ChildrenNavigation({ curPage, parentPage, siteData, parentSlug = "" }) {
  parentSlug = parentSlug || (parentPage?.slug ? parentPage?.slug : curPage?.slug);

  let prevTitle = null;
  let prevSlug = null;
  let prevLink = null;

  if (curPage?.slug !== parentSlug) {
    prevTitle = siteData?.prevPage?.title || siteData?.prevPage?.node?.title;
    prevSlug = siteData?.prevPage?.slug || siteData?.prevPage?.node?.slug;
    prevLink = `/${parentSlug}/${prevSlug}`;
  }

  const nextTitle = siteData?.nextPage?.title || siteData?.nextPage?.node?.title;
  const nextSlug = siteData?.nextPage?.slug || siteData?.nextPage?.node?.slug;
  const nextLink = `/${parentSlug}/${nextSlug}`;

  return (
    <div id="page-navigation" className="relative block w-full mt-2 mb-4">
      <div className="flex items-center justify-between w-full">
        <div className="absolute left-0 items-center justify-start">{prevTitle && <CustomLink direction="left" to={prevLink} label={prevTitle} title={prevTitle} />}</div>
        <div className="absolute right-0 items-center justify-end">{nextTitle && <CustomLink direction="right" to={nextLink} label={nextTitle} title={nextTitle} />}</div>
      </div>
    </div>
  );
}
