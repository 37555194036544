import React from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

export default function MetaHead({ seoObject, addMap = false, children }) {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{seoObject.seo?.title}</title>
        <meta name="robots" content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1" />
        <meta name="description" content={seoObject.seo?.opengraphDescription} />
        <link rel="canonical" href={seoObject.link} />
        <meta property="og:title" content={seoObject.seo?.opengraphTitle} />
        <meta property="og:type" content={seoObject.seo?.opengraphType} />
        <meta property="og:description" content={seoObject.seo?.opengraphDescription} />
        <meta property="og:site_name" content="AEON" />
        <meta property={`${seoObject.seo.opengraphType}:modified_time`} content={seoObject.modified} />
        <meta property="og:image" content={seoObject.seo?.opengraphImage?.mediaItemUrl} />
        <meta property="og:image:width" content={seoObject.seo?.opengraphImage?.mediaDetails.width} />
        <meta property="og:image:height" content={seoObject.seo?.opengraphImage?.mediaDetails.height} />
        <meta property="og:image:type" content={seoObject.seo?.opengraphImage?.mimeType} />
        <meta property="og:url" content={seoObject.seo?.opengraphUrl} />
        <meta name="twitter:label1" content="Est. reading time" />
        <meta name="twitter:data1" content={`${seoObject.seo?.readingTime} minute`} />
      </Helmet>
    </HelmetProvider>
  );
}
