import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import MetaHead from "../components/shared/MetaHead";
import BackgroundImagePanel from "../components/shared/BackgroundImagePanel";
import DropdownContent from "../components/shared/layouts/DropdownContent";

import usePage from "../contexts/PageContext";
import { gql, useQuery } from "@apollo/client";
import { ADD_PAGES } from "../lib/graphql";

export default function FAQS(props) {
  const { siteData, setSiteData } = usePage();
  console.log("siteData DropdownPage", siteData, props);

  const location = useLocation();

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/") ? location.pathname.split("/").filter((item) => item !== "") : location.pathname;
  const slugId = curLocations[curLocations.length - 1];

  let curQueries = ADD_PAGES;
  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;

  const {
    loading,
    error,
    data: pageData,
  } = useQuery(GET_PAGE_DATA, {
    variables: { id: slugId },
    skip: siteData?.pages[curLocation],
  });

  useEffect(() => {
    if (pageData && pageData.pages) {
      console.log("recruiting pageData", pageData);

      let newSiteData = { ...siteData };
      let newPage = pageData.pages?.nodes[0] || [];
      const featuredImage = newPage?.featuredImage?.node || null;

      const newPageContent = newPage.content.replaceAll("<h2>", "|<h2>").split("|");
      newPageContent.shift();
      const newContent = [];

      newPageContent.forEach((item, idx) => {
        const newItems = item.replace("</h2>", "</h2>|").split("|");
        newContent.push({ question: newItems[0], answer: newItems[1] });
      });
      newSiteData.curPanelBackground = featuredImage?.mediaItemUrl || null;

      newSiteData.pages = {
        ...siteData.pages,
        [slugId]: {
          // we store the object as whole location.path - '/'
          ...newPage,
          featuredImage: featuredImage,
          pageContent: newContent,
        },
      };

      newSiteData.prevPage = null;
      newSiteData.nextPage = null;

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: location.pathname,
        curLocations: curLocations,
        curSlug: slugId,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage = siteData.pages[slugId];
  const featuredImage = curPage?.featuredImage?.mediaItemUrl || null;

  useEffect(() => {
    //console.log('dropdown curPage siteData', curPage, siteData)
    if (curPage) {
      let newSiteData = { ...siteData };

      const featuredImage = curPage?.featuredImage?.node || null;
      if (featuredImage?.mediaItemUrl) newSiteData.curPanelBackground = featuredImage.mediaItemUrl;

      setSiteData({
        ...newSiteData,
        curParent: null, //curPage
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curSlug: slugId,
        isLoading: false,
      });
    }
  }, [ curPage ]);

  if (!siteData) return null;

  return (
    <>
      {curPage && <MetaHead seoObject={curPage} />}
      <BackgroundImagePanel url={featuredImage} />
      <main id="main-container" className="bg-[#fcfcfc] min-h-screen w-full z-10 mt-[224px] relative grid grid-cols-1 min-[576px]:max-w-[540px] min-[768px]:max-w-[720px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto mb-4">
        <section className={`${!siteData.isLoading && " -mt-[81px]"} w-full col-span-3`}>
          <DropdownContent page={curPage} />
        </section>
      </main>
    </>
  );
}
