import React from "react";
import DefaultSideContent from "./DefaultSideContent";
import usePage from "../../../contexts/PageContext";

export default function SearchSidebar({ size, page, parent, children }) {
  const { siteData } = usePage();

  const isLoading = false;

  return (
    <aside id="secondary" className="sidebar sticky top-[53px] h-100 pt-[1.2rem] mb-4 px-1 bg-[#fcfcfc]">
      <section className="rounded-sm sticky top-[53px] p-[1.35rem] pt-4 z-50 min-h-[100px]">{size === "full" && <DefaultSideContent locations={siteData.locations} loading={isLoading} />}</section>
    </aside>
  );
}
