import React, { useRef } from "react";

const ScrollableListItem = ({ item, idx }) => {
  const listRef = useRef(null);

  const handleItemClick = (idx) => {
    // Calculate the target scroll position
    //window.scrollBy(0, -100);

    uncheckAllCheckboxes(idx);
    // Perform smooth scrolling to the target position
    setTimeout(() => {
      const mainContent = document.getElementById("myList");
      const targetPosition = listRef.current.offsetTop * 2;
      // window.scrollBy(0, targetPosition);
      //listRef.current.scrollIntoView();
      //listRef.current.scroll(0, mainContent.offsetTop);
      console.log("target", targetPosition);
      console.log("listRef.current.offsetTop", listRef.current.offsetTop);
      console.log("mainContent.offsetTop", mainContent.offsetTop);
      // window.scrollBy(mainContent.offsetTop, targetPosition)
      window.scrollTo({
        top: listRef.current,
        behavior: "smooth",
      });
    }, 10);
    // setTimeout(() => {
    //   const targetPosition = listRef.current.offsetTop + 100;
    //   // window.scrollBy(0, targetPosition);
    //   console.log('target', targetPosition)
    //   listRef.current.scrollIntoView();

    // }, 100)
  };
  const uncheckAllCheckboxes = (idx) => {
    // Get all checkbox elements on the page
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

    // Uncheck each checkbox
    checkboxes.forEach((checkbox) => {
      if (checkbox.id !== `input-${idx}`) checkbox.checked = false;
    });
  };
  return (
    <li
      key={idx}
      className=" flex flex-col justify-center pt-2 pb-1 divider-dots"
      // onClick={() => scrollToTop(idx)}>
      onClick={() => handleItemClick(idx)}
    >
      <input type="checkbox" id={`input-${idx}`} className="peer appearance-none" />
      <label className="hover:pl-1 transition-all cursor-pointer select-none font-georgia italic trans-ease peer-checked:text-[#008ae1b3] hover:text-[#008ae1b3]" htmlFor={`input-${idx}`} ref={listRef} dangerouslySetInnerHTML={{ __html: item.question }} />
      <div className="overflow-hidden drop-item-content transition-all trans-ease animate-out peer-checked:animate-in peer-checked:fade-in fade-out">
        <div className="mb-1  animate-in spin-in" dangerouslySetInnerHTML={{ __html: item.answer }} />
      </div>
    </li>
  );
};

export default ScrollableListItem;
