import React from "react";
import { Link, useLocation } from "react-router-dom";
import usePage from "../../../contexts/PageContext";
import Locations from "../../Locations";

export default function PageNavigation({ size, page, parent, children, parentSlugOverride }) {
  const { siteData } = usePage();
  const location = useLocation();
  const curLocations = location.pathname.includes("/") ? location.pathname.split("/").filter((item) => item !== "") : location.pathname;
  const isLoading = curLocations[0] !== siteData.curLocations[0] || siteData.isLoading;
  const locationSlug = location.pathname.includes("location/") ? "location" : null;
  const parentSlug = parentSlugOverride || locationSlug || page?.parent?.node?.slug || parent?.slug || parent?.node?.slug || page?.slug || null;

  return (
    <aside id="secondary" className="sidebar sticky top-[53px] h-100 pt-[1.2rem] mb-4 px-1 bg-[#fcfcfc]">
      <section className="rounded-sm sticky top-[53px] p-[1.35rem] pt-4 z-50 min-h-[100px]">
        <ul className={`${isLoading ? "opacity-0" : ""} uppercase font-frutiger-ce4 border-b-4 border-[#33b1e7] mb-4`}>
          <li className="transition-all pb-1 xl:pb-2 text-lg xl:text-xl">
            {parentSlug !== "location/" && (
              <Link to={`/${parentSlug || ""}`} className={`flex hover:pl-1 transition-all text-[#008ae1] py-.5 lg:py-1 xl:py-1.5 w-fit`}>
                <span>{parent?.title || page?.title}</span>
              </Link>
            )}
            {parentSlug === "location/" && (
              <a className={`flex hover:pl-1 transition-all text-[#008ae1] py-1 xl:py-1.5 w-fit`}>
                <span>{parent?.title || page?.title}</span>
              </a>
            )}
          </li>
          {children &&
            children.length > 0 &&
            children.map((child, idx) => (
              <li key={idx} className="divider-dots-sidebar transition-all">
                {/* <Link to={`${(parent.slug || parent.node.slug) || page.slug}${(child.slug || child.node?.slug) || ''}`}> */}
                <Link to={`/${parentSlug ? parentSlug + "/" : ""}${child?.slug || child?.node?.slug || ""}`} className={`flex hover:pl-1 trans-ease ${page?.slug === (child?.slug || child?.node?.slug) ? "text-[#008ae1]" : "hover:text-[#008ae1]"} py-1 xl:py-1.5 w-fit`}>
                  <span className="mt-[.15rem]">{child.title || child.node.title}</span>
                </Link>
              </li>
            ))}
        </ul>
        {size === "full" && (
          <div className={`${isLoading ? "opacity-0 " : ""}`}>
            <div className="side-blue text-center mb-4 rounded-md pb-2 custom-shadow">
              <div id="interviewing-year-round" className="position-relative m-auto tighten trans-ease-all px-1 md:pb-1 md:px-2 lg:pb-2 lg:px-3">
                <h5 className="white-title text-center pt-3">
                  Interviewing
                  <br />
                  Year Round Worldwide
                </h5>
                <div className="mt-2 mb-3 w-36 border-b border-black mx-auto" />
                <Link title="Submit Application" to="/recruiting-locations" className="group flex items-center justify-center font-bold drop-shadow hover:drop-shadow-lg custom-shadow mx-auto  trans-ease-all rounded-md mt-3 text-sm btn btn-grey">
                  <span className="max-xl:text-[.75rem]">SUBMIT APPLICATION</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="#000000" className="blue w-3 h-3 mb-[.1rem]">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="#000000" className="trans-ease-all opacity-0 w-0 group-hover:w-3 group-hover:opacity-100 ml-[-6px]  h-3 mb-[.1rem]">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                  </svg>
                </Link>
              </div>
            </div>
            <div className="side-grey text-center mb-4 rounded-md pb-2 custom-shadow">
              <div id="frequently-asked-questions" className="position-relative m-auto tighten trans-ease-all px-1 md:pb-1 md:px-2 lg:pb-2 lg:px-3">
                <h5 className="black-title black-line text-center pt-3">
                  Frequently
                  <br />
                  Asked Questions
                </h5>
                <div className="mt-2 mb-3 w-36 border-b border-black mx-auto" />
                <Link title="Get the Frequently Asked Questions" to="/frequently-asked-questions" className="group flex items-center justify-center font-bold drop-shadow hover:drop-shadow-lg  custom-shadow mx-0 w-full trans-ease-all rounded-md mt-3 text-sm btn btn-blue">
                  <span className="max-xl:text-[.75rem]">GET THE FAQ'S</span>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="#ffffff" className="blue w-3 h-3 mb-[.1rem]">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                  </svg>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="#ffffff" className="trans-ease-all opacity-0 w-0 group-hover:w-3 group-hover:opacity-100 ml-[-6px]  h-3 mb-[.1rem]">
                    <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                  </svg>
                </Link>
              </div>
            </div>
            {siteData?.locations.length && <Locations locations={siteData.locations} position="side" />}
          </div>
        )}
      </section>
    </aside>
  );
}
