import { useEffect, useState } from 'react';

export const useHandleScroll = () => {
  const [curScrollDirection, setScrollDirection] = useState(0);
  const [curScrollPosition, setScrollPosition] = useState(0);

  let throttleTimer = false;

  const throttle = (callback, time) => {
    if (throttleTimer) return;

    throttleTimer = true;
    setTimeout(() => {
      callback();
      throttleTimer = false;
    }, time);
  };

  useEffect(() => {
    let prevScroll = 0;

    const getScrollPosition = () => {
      const isCSS1Compat = (document.compatMode || '') === 'CSS1Compat';
      const scrollTop = isCSS1Compat ? document.documentElement.scrollTop : document.body.scrollTop;

      const getPosition = window.pageXOffset !== undefined ? window.pageYOffset : scrollTop;
      // console.log('getScrollPosition', getPosition);
      setScrollPosition(getPosition);
      return getPosition;
    };

    const isScrollingDown = () => {
      const scrolledPosition = getScrollPosition();
      const isScrollDown = scrolledPosition > prevScroll;
      // let isScrollDown = curScrollPosition > prevScroll ? true : false;

      prevScroll = scrolledPosition;
      // prevScroll = curScrollPosition;

      //console.log('throttled isScrollDown', isScrollDown, scrolledPosition);

      return isScrollDown;
    };

    const checkScroll = () => {
      setScrollDirection(isScrollingDown());
      // getScrollPosition();
      // console.log('throttled isScrollingDown', isScrollingDown())
    };

    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');

    const checkWindowMotion = () => {
      if (mediaQuery && !mediaQuery.matches) {
        getScrollPosition();
        //throttle(checkScroll, 200);
        throttle(checkScroll, 300);
      }
    };

    window.addEventListener('scroll', checkWindowMotion);

    // updatePosition();
    return () => window.removeEventListener('scroll', checkWindowMotion);
  }, []);
  // console.log('curScrollDirection is Down', curScrollDirection)
  return [curScrollDirection, curScrollPosition];
};
