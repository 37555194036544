import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { TEInput, TESelect } from "tw-elements-react";
import { Controller, useForm } from "react-hook-form";

import { wpcf7Url, defaultValues, visaTypeOptions, soonestAvailableOptions, officeAppliedToOptions, CustomInput, CustomControlledInput, CustomControlledDatePicker, CustomCheckbox } from "../lib/form-helper";

export default function OnlineApplicationContent({ page }) {
  const [ pageContent, setPageContent ] = useState({
    afterSubmitting: null,
  });

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const interviewType = queryParams.get("type");
  const isWorldWide = interviewType === "worldwide-interviews";
  const formId = 26355;

  const {
    register,
    unregister,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [ newFormData, setFormData ] = useState({ ...defaultValues });
  const pageRef = useRef();

  useEffect(() => {
    const newParams = {};

    if (isWorldWide) {
      newParams.addLocation = queryParams.get("add-location") || null;
      newParams.addWeek = queryParams.get("add-week") || null;
      newParams.addDeadline = queryParams.get("add-deadline") || null;
    } else {
      newParams.addLocation = queryParams.get("add-location") || null;
      newParams.addApplicationPeriod = queryParams.get("add-application-period") || null;
      newParams.addDate = queryParams.get("add-date") || null;
    }

    const newFormDataItems = {
      ...newFormData,
      type: interviewType,
      ...newParams,
    };
    setFormData(newFormDataItems);
  }, []);

  useEffect(() => {
    if (page && page?.children && page.children.length > 0) {
      let newPageContent = { ...pageContent };
      const sideBarID = "recruiting-offices";

      const toApplyToAeon = page.children.find((child) => child.node.slug === sideBarID)?.node;
      const toApplyToAeonArr = toApplyToAeon ? toApplyToAeon.content.split(/<div class="widget-wrap">/) : null;

      toApplyToAeonArr.shift();
      toApplyToAeonArr[0] = toApplyToAeonArr[0].replace(/<h5.*?>/gi, '<h5 class="border-b-4 border-[#33b1e7] font-frutiger-roman font-bold uppercase text-[#24292e] text-[1.25rem] mb-4">');

      newPageContent.toApplyToAeonIntro = toApplyToAeonArr[0] ? `<div className="widget-wrap">${toApplyToAeonArr[0]}` : "";

      setPageContent({ ...newPageContent });
    }
  }, [ page ]);

  const onInvalid = (e) => {
    //console.log("onInvalid", e);
  };

  const onSubmit = (data) => {
    const fileResume = document.getElementById("file-resume")?.files[0];
    const fileEssay = document.getElementById("file-essay")?.files[0];

    const soonestAvailable = data["soonest-available"].text;

    const formData = new FormData();
    for (const key in data) {
      if (key !== "file-resume" && key !== "file-essay") {
        if (data[key]?.value) {
          formData.append(key, data[key].value);
        } else {
          formData.append(key, data[key]);
        }
      }
    }
    formData.append("_wpcf7_unit_tag", formId);

    formData.append("file-essay", fileEssay);
    formData.append("file-resume", fileResume);
    // formData.append("type", interviewType);

    formData.append("applied-before", newFormData.appliedBefore);
    formData.append("has-degree", newFormData.hasDegree);
    formData.append("has-passport", newFormData.hasPassport);
    formData.append("has-visa", newFormData.hasVisa);
    formData.append("soonest-available", soonestAvailable);

    formData.append("_wpcf7", formId.toString());
    formData.append("_wpcf7cf_hidden_group_fields", JSON.stringify([ "received-date" ]));
    // formData.append("_wpcf7cf_hidden_groups", JSON.stringify([ "status-selected" ]));
    formData.append(
      "_wpcf7cf_options",
      JSON.stringify({
        form_id: formId,
        conditions: [
          { then_field: "status-selected", and_rules: [ { if_field: "office", operator: "not equals", if_value: "Texas" } ] },
          { then_field: "-1", and_rules: [ { if_field: "-1", operator: "equals", if_value: "" } ] },
        ],
        settings: { animation: "yes", animation_intime: 200, animation_outtime: 200, conditions_ui: "normal", notice_dismissed: false },
      })
    );

    const fetchData2 = async () => {
      const url = wpcf7Url(formId);
      const config = {
        headers: {
          "content-type": "multipart/form-data",
        },
      };

      try {
        const results = await axios.post(url, formData, config);

        if (results.statusText === "OK") {
          if (results?.data?.invalid_fields && results.data.invalid_fields.length > 0) {
            setFormData({ ...newFormData, isSuccess: false, isInvalid: true, errors: [ ...results.data.invalid_fields ], errorMessage: results?.data?.message || null });
          } else {
            setFormData({ ...newFormData, isSuccess: true, isInvalid: false });
          }

          window.scrollTo({
            top: 0,
            behavior: "auto",
          });
        }
      } catch (error) {
        console.error("Error submitting form to:", error.response);
        setFormData({ ...newFormData, isError: true, errorMessage: "An unknown error has occured, please contact us for assistance." });
      }
    };
    fetchData2();
  };

  return (
    <article ref={pageRef} className="p-8 pt-12 bg-[#fcfcfc] z-50 min-h-full">
      {page && (
        <div className="trans-ease-slow">
          <h1 className="font-frutiger-light text-[30px] font-bold uppercase text-[#24292e]">{page.title}</h1>
          <div className={`${newFormData?.isSuccess ? "h-0" : "h-auto"}`}>{newFormData?.isSuccess && <div className="post-content mt-4 mb-8 xl:mt-8" dangerouslySetInnerHTML={{ __html: page.successObject.success }} />}</div>
          {newFormData?.isError && <div className="post-content mt-4 mb-8 xl:mt-8" dangerouslySetInnerHTML={{ __html: page.newPageError }} />}
          {!newFormData?.isError && !newFormData?.isSuccess && (
            <>
              <aside className="lg:hidden w-full">
                <section className="blue-triangles mt-6 pb-2 bg-[#fcfcfc] trans-ease-all transition-all">{<div dangerouslySetInnerHTML={{ __html: pageContent.toApplyToAeonIntro }} />}</section>
              </aside>
              {isWorldWide && (
                <ul className="list-unstyled corporate">
                  {newFormData.addLocation && (
                    <li>
                      <span className="blue-strong">Office to Apply: </span>
                      <span>{newFormData.addLocation}</span>
                    </li>
                  )}
                  {newFormData.addWeek && (
                    <li>
                      <span className="blue-strong">Week of Interview: </span>
                      <span>{newFormData.addWeek}</span>
                    </li>
                  )}
                  {newFormData.addDeadline && (
                    <li>
                      <span className="blue-strong">Application Deadline: </span>
                      <span>{newFormData.addDeadline}</span>
                    </li>
                  )}
                </ul>
              )}
              <div className="post-content mt-4 mb-8 xl:mt-8" dangerouslySetInnerHTML={{ __html: page.newPageContent }} />
              {newFormData?.isInvalid && (
                <div className="mb-2">
                  <span className="text-[#e80000] mb-2 block">{newFormData?.errorMessage && newFormData.errorMessage}</span>
                  {newFormData?.errors?.length && (
                    <div className="mb-4">
                      <span>
                        The following fields were found to be invalid:
                        {newFormData.errors.map((error, index) => {
                          return (
                            <span key={error.field}>
                              {index < newFormData.errors.length - 1 && ","}
                              <span className="ml-1">{error.field}</span>
                            </span>
                          );
                        })}
                      </span>
                    </div>
                  )}
                </div>
              )}
              <form className="online-form invalidated" onSubmit={handleSubmit(onSubmit, onInvalid)}>
                <section className="mb-6">
                  <h4 className="blue-strong mb-4">Contact Information</h4>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-x-3 gap-y-5 mb-2">
                    <CustomInput register={register} error={errors["first-name"]} label="First Name" keyField="first-name" rules={{ required: true, maxLength: 56 }} />
                    <TEInput type="text" label="Middle Name" {...register("middle-name", { maxLength: 56 })}></TEInput>
                    <CustomInput register={register} error={errors["last-name"]} label="Last Name" keyField="last-name" rules={{ required: true, maxLength: 56 }} />
                    <CustomInput register={register} error={errors["email"]} label="Email address" keyField="email" rules={{ required: true, maxLength: 56 }} />
                  </div>
                </section>
                <section className="mb-6">
                  <h4 className="blue-strong inline-block mb-4">Current Location</h4>
                  <span className="ml-2 inline-block text-sm mb-2">(Place you will be for the next month)</span>
                  <div className="grid grid-cols-1 md:grid-cols-3 gap-x-3 gap-y-5 mb-2">
                    <CustomInput register={register} error={errors["city"]} label="City" keyField="city" rules={{ required: true, maxLength: 128 }} type="text" />
                    <CustomInput register={register} error={errors["state"]} label="State/Province" keyField="state" rules={{ required: true, maxLength: 56 }} type="text" />
                  </div>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-x-3 gap-y-5 mt-5">
                    <CustomInput register={register} error={errors["country"]} label="Country" keyField="country" rules={{ required: true, maxLength: 48 }} type="text" />
                  </div>
                </section>
                <section>
                  <label className="required inline-block pl-[0.15rem] hover:cursor-pointer" htmlFor="appliedBefore">
                    Have you applied to or worked for AEON before?
                  </label>
                  <div className="ml-4 flex justify-start mt-2">
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.appliedBefore === "Yes"}
                      value="Yes"
                      id="has-applied"
                      keyField="applied-before"
                      onChange={() => {
                        setFormData({ ...newFormData, appliedBefore: "Yes" });
                        register("office-applied", { required: true, maxLength: 256 });
                        register("year-applied", { required: true, maxLength: 256 });
                      }}
                    />
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.appliedBefore === "No"}
                      value="No"
                      id="has-not-applied"
                      keyField="applied-before"
                      onChange={() => {
                        setFormData({ ...newFormData, appliedBefore: "No" });
                        unregister("office-applied");
                        unregister("year-applied");
                      }}
                    />
                  </div>
                  <div className={`mt-4 ml-4 overflow-hidden ${newFormData.appliedBefore === "Yes" ? "h-auto visible opacity-100" : "opacity-0 invisible h-0"} flex transition-all trans-ease-fast  w-full top-5 right-3`}>
                    {newFormData.appliedBefore === "Yes" && (
                      <div className="flex flex-col">
                        <label className="blue pl-[0.15rem] hover:cursor-pointer" htmlFor="exampleCheck3">
                          The office and year you applied:
                        </label>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-3 gap-y-5 mt-2 mb-6">
                          <div className="input-wrapper">
                            <Controller
                              control={control}
                              name="office-applied"
                              rules={{ required: true }}
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TESelect
                                  clearBtn
                                  preventFirstSelection
                                  data={officeAppliedToOptions}
                                  label={`Office Applied To`}
                                  className="max-w-[440px] w-full required"
                                  id="office-applied"
                                  onBlur={onBlur} // notify when input is touched/blur
                                  selected={value}
                                  onValueChange={onChange}
                                  theme={{
                                    selectInput:
                                      "peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear peer-focus:text-primary motion-reduce:transition-none disabled:bg-neutral-100 dark:disabled:bg-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary cursor-pointer disabled:cursor-default required",
                                  }}
                                ></TESelect>
                              )}
                            />
                            <span className="text-[#e80000] text-sm font-thin mt-2 trans-ease-slow">{errors["soonest-available"] && "This field is required."}</span>
                          </div>
                          <CustomControlledDatePicker error={errors["year-applied"]} rules={{ required: true }} views={[ "year" ]} label="Year Applied" keyField="year-applied" control={control} />
                        </div>
                      </div>
                    )}
                  </div>
                </section>
                <section>
                  <label className="required inline-block pl-[0.15rem] hover:cursor-pointer" htmlFor="hasDegree">
                    Do you possess a Bachelor's degree?
                  </label>
                  <div className="ml-4 flex justify-start mt-2">
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.hasDegree === "Yes"}
                      value="Yes"
                      id="has-degree"
                      keyField="degree"
                      onChange={() => {
                        setFormData({ ...newFormData, hasDegree: "Yes" });
                        register("date-graduating", { required: true, maxLength: 256 });
                      }}
                    />
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.hasDegree === "No"}
                      value="No"
                      id="has-no-degree"
                      keyField="degree"
                      onChange={() => {
                        setFormData({ ...newFormData, hasDegree: "No" });
                        unregister("date-graduating");
                      }}
                    />
                  </div>
                  <div className={`mt-4 ml-4 overflow-hidden ${newFormData.hasDegree === "No" ? "h-auto visible opacity-100" : "opacity-0 invisible h-0"} flex transition-all trans-ease-fast  w-full top-5 right-3`}>
                    {newFormData.hasDegree === "No" && (
                      <div className="flex flex-col">
                        <label className="blue pl-[0.15rem] hover:cursor-pointer" htmlFor="exampleCheck3">
                          Expected date of graduation:
                        </label>
                        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-3 gap-y-5 mt-2 mb-6">
                          <CustomControlledDatePicker error={errors["date-graduating"]} rules={{ required: true }} placeholder="Month/Year" views={[ "year", "month" ]} label="Month/Year" keyField="date-graduating" control={control} />
                        </div>
                      </div>
                    )}
                  </div>
                </section>
                <section>
                  <label className="required inline-block pl-[0.15rem] hover:cursor-pointer" htmlFor="hasPassport">
                    Do you possess a current or active passport?
                  </label>
                  <div className="ml-4 flex justify-start mt-2">
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.hasPassport === "Yes"}
                      value="Yes"
                      id="has-passport"
                      keyField="passport"
                      onChange={() => {
                        setFormData({ ...newFormData, hasPassport: "Yes" });
                        register("passport-country", { required: true, maxLength: 256 });
                        register("passport-date", { required: true, maxLength: 256 });
                      }}
                    />
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.hasPassport === "No"}
                      value="No"
                      id="has-no-passport"
                      keyField="passport"
                      onChange={() => {
                        setFormData({ ...newFormData, hasPassport: "No" });
                        unregister("passport-country");
                        unregister("passport-date");
                      }}
                    />
                  </div>
                  <div className={`mt-4 ml-4 overflow-hidden ${newFormData.hasPassport === "Yes" ? "h-auto visible opacity-100" : "opacity-0 invisible h-0"} flex transition-all trans-ease-fast  w-full top-5 right-3`}>
                    {newFormData.hasPassport === "Yes" && (
                      <div className="flex flex-col">
                        <label className="blue pl-[0.15rem] hover:cursor-pointer" htmlFor="exampleCheck3">
                          The country and expiration date:
                        </label>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-3 gap-y-5 mt-2 mb-6">
                          <CustomControlledInput error={errors["passport-country"]} keyField="passport-country" type="text" rules={{ required: true }} label="Country" control={control} />
                          <CustomControlledDatePicker error={errors["passport-date"]} rules={{ required: true }} placeholder="Month/Year" views={[ "year", "month" ]} label="Month/Year" keyField="passport-date" control={control} />
                        </div>
                      </div>
                    )}
                  </div>
                </section>
                <section>
                  <label className="required inline-block pl-[0.15rem] hover:cursor-pointer" htmlFor="hasPassport">
                    Do you possess a current or active visa to live and/or work in Japan?
                  </label>
                  <div className="ml-4 flex justify-start mt-2">
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.hasVisa === "Yes"}
                      value="Yes"
                      id="has-visa"
                      keyField="visa"
                      onChange={() => {
                        setFormData({ ...newFormData, hasVisa: "Yes" });
                        register("visa-date", { required: true, maxLength: 256 });
                      }}
                    />
                    <CustomCheckbox
                      type="radio"
                      checked={newFormData.hasVisa === "No"}
                      value="No"
                      id="has-no-visa"
                      keyField="visa"
                      onChange={() => {
                        setFormData({ ...newFormData, hasVisa: "No" });
                        unregister("visa-date");
                      }}
                    />
                  </div>
                  <div className={`mt-4 ml-4 overflow-hidden ${newFormData.hasVisa === "Yes" ? "h-auto visible opacity-100" : "opacity-0 invisible h-0"} flex transition-all trans-ease-fast  w-full top-5 right-3`}>
                    {newFormData.hasVisa === "Yes" && (
                      <div className="flex flex-col">
                        <label className="blue pl-[0.15rem] hover:cursor-pointer" htmlFor="hasVisa">
                          The type of visa you currently possess and expiration date:
                        </label>
                        <div className="grid grid-cols-1 lg:grid-cols-3 gap-x-2 gap-y-4 mt-4 mb-6">
                          <div className="input-wrapper">
                            <Controller
                              control={control}
                              name="visa-type"
                              rules={{ required: true }}
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TESelect
                                  clearBtn
                                  preventFirstSelection
                                  data={visaTypeOptions}
                                  label={`Visa Type`}
                                  className="max-w-[440px] w-full required"
                                  id="visa-type"
                                  onBlur={onBlur} // notify when input is touched/blur
                                  selected={value}
                                  onValueChange={onChange}
                                  theme={{
                                    selectInput:
                                      "peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear peer-focus:text-primary motion-reduce:transition-none disabled:bg-neutral-100 dark:disabled:bg-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary cursor-pointer disabled:cursor-default required",
                                  }}
                                ></TESelect>
                              )}
                            />
                          </div>
                          <CustomControlledDatePicker error={errors["visa-date"]} rules={{ required: true }} placeholder="Month/Year" views={[ "year", "month" ]} label="Month/Year" keyField="visa-date" control={control} />
                        </div>
                      </div>
                    )}
                  </div>
                </section>
                <section>
                  <div className="flex flex-col">
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-3 gap-y-5 mt-2 mb-4">
                      <CustomInput register={register} error={errors["how-learn-about"]} label="How did you learn about opportunities at AEON?" keyField="how-learn-about" rules={{ required: true, maxLength: 56 }} />
                    </div>
                    <div className="grid grid-cols-1 xl:grid-cols-2 gap-x-3 gap-y-5 mt-2 mb-6">
                      <div className="input-wrapper">
                        <Controller
                          control={control}
                          name="soonest-available"
                          rules={{ required: true }}
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TESelect
                              clearBtn
                              preventFirstSelection
                              data={soonestAvailableOptions}
                              label={`Soonest available date to depart to Japan?`}
                              className="max-w-[440px] w-full required"
                              id="soonest-available"
                              onBlur={onBlur} // notify when input is touched/blur
                              selected={value}
                              onValueChange={onChange}
                              theme={{
                                selectInput:
                                  "peer block min-h-[auto] w-full rounded border-0 bg-transparent outline-none transition-all duration-200 ease-linear peer-focus:text-primary motion-reduce:transition-none disabled:bg-neutral-100 dark:disabled:bg-neutral-700 dark:text-neutral-200 dark:placeholder:text-neutral-200 dark:peer-focus:text-primary cursor-pointer disabled:cursor-default required",
                              }}
                            ></TESelect>
                          )}
                        />
                        <span className="text-[#e80000] text-sm font-thin mt-2 trans-ease-slow">{errors["soonest-available"] && "This field is required."}</span>
                      </div>
                    </div>
                  </div>
                </section>
                <section>
                  <div className="mb-8">
                    <label htmlFor="file-resume" className="required mb-2 inline-block ">
                      Please attach your resume/CV: <span className="blue text-sm">(txt|doc|pdf|docx)</span>
                    </label>
                    <div className="input-wrapper">
                      <input
                        className="max-w-[600px] required relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-secondary-500 bg-transparent bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-surface transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:me-3 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-e file:border-solid file:border-inherit file:bg-transparent file:px-3  file:py-[0.32rem] file:text-surface focus:border-primary focus:text-gray-700 focus:shadow-inset focus:outline-none dark:border-white/70 dark:text-white  file:dark:text-white"
                        type="file"
                        accept=".txt, .doc, .pdf, .docx, .TXT, .DOC, .PDF, .DOCX"
                        name="file-resume"
                        id="file-resume"
                        {...register("file-resume", { required: true })}
                      />
                      <span className="text-[#e80000] text-sm font-thin mt-2 trans-ease-slow">{errors["file-resume"] && "This field is required."}</span>
                    </div>
                  </div>
                  <div className="mb-8">
                    <label htmlFor="file-essay" className="required mb-2 inline-block ">
                      Please attach your "Why I want to live and work in Japan" essay: <span className="blue text-sm">(txt|doc|pdf|docx)</span>
                    </label>
                    <div className="input-wrapper">
                      <input
                        className="max-w-[600px] required relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-secondary-500 bg-transparent bg-clip-padding px-3 py-[0.32rem] text-base font-normal text-surface transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:me-3 file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-e file:border-solid file:border-inherit file:bg-transparent file:px-3  file:py-[0.32rem] file:text-surface focus:border-primary focus:text-gray-700 focus:shadow-inset focus:outline-none dark:border-white/70 dark:text-white  file:dark:text-white"
                        type="file"
                        accept=".txt, .doc, .pdf, .docx, .TXT, .DOC, .PDF, .DOCX"
                        name="file-essay"
                        id="file-essay"
                        {...register("file-essay", { required: true })}
                      />
                      <span className="text-[#e80000] text-sm font-thin mt-2 trans-ease-slow">{errors["file-essay"] && "This field is required."}</span>
                    </div>
                  </div>
                </section>

                <button className="btn btn-blue font-bold block pt-2 pb-2 px-10 w-[200px] mt-2 text-sm uppercase rounded-md trans-ease-all" type="submit">
                  Submit Application
                </button>
              </form>
            </>
          )}
        </div>
      )}
    </article>
  );
}
