import { useEffect, useRef, useState } from "react";

function Popover({ children, content, trigger = "click" }) {
  const [ show, setShow ] = useState(false);
  const wrapperRef = useRef(null);

  const handleMouseOver = () => {
    if (trigger === "hover") {
      setShow(true);
      setTimeout(() => {
        wrapperRef.current.classList.add("animated");
      }, 100);
    }
  };

  const handleMouseLeft = () => {
    if (trigger === "hover") {
      wrapperRef.current.classList.remove("animated");
      setShow(false);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setShow(false);
      }
    }

    if (show) {
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }
  }, [ show, wrapperRef ]);

  return (
    <div ref={wrapperRef} onMouseEnter={handleMouseOver} onMouseLeave={handleMouseLeft} className="map-thumb-wrap trans-ease-slow w-fit h-fit relative flex justify-center">
      <div onClick={() => setShow(!show)}>{children}</div>
      <div hidden={!show} className="min-w-fit absolute bottom-[100%] z-50">
        {/* <div hidden={!show} className={`trans-ease-slow opacity-0 ${show ? "opacity-100" : ""} min-w-fit absolute bottom-[100%] z-50 transition-all`}> */}

        {content}
      </div>
    </div>
  );
}

export default Popover;
