import React from "react";
import { Link } from "react-router-dom";
export default function Footer({ sticky }) {
  return (
    <footer id="footer" className={`bg-[#e1e3e5] w-full z-50 shadow relative py-4`}>
      <div className="container max-w-7xl mx-auto flex-col sm:flex-row flex items-center justify-between text-xs text-[#191919] font-frutiger-roman uppercase">
        <ul id="menu-footer" className="flex flex-col sm:flex-row list-none sm:divider-line h-fit w-full">
          <li>
            <Link to="/testimonials-from-our-staff" className="w-fit inline-block p-1 pb-2 border-b border-transparent hover:border-[#a2a2a2]">
              From our staff
            </Link>
          </li>
          <li>
            <Link to="/application-requirements/contract-stipulations-benefits" className="w-fit inline-block p-1 pb-2 border-b border-transparent hover:border-[#a2a2a2]">
              <span className="sm:hidden">Contract Stipulations & Benefits</span>
              <span className="hidden sm:block">
                Contract Stipulations
                <br />& Benefits
              </span>
            </Link>
          </li>
          <li>
            <Link to="/frequently-asked-questions" className="w-fit inline-block p-1 pb-2 border-b border-transparent hover:border-[#a2a2a2]">
              <span className="sm:hidden">Frequently Asked Questions</span>
              <span className="hidden sm:block">
                Frequently
                <br />
                Asked Questions
              </span>
            </Link>
          </li>
        </ul>
        <ul className="flex social-icons m-0 list-unstyled list-inline pt-2 pb-2 w-full">
          <li className="flex items-center justify-center sm:justify-end sm:mr-3 w-full">
            <div className="hidden sm:block widget widget-title">FOLLOW US </div>
            <div className="social-icons-widget">
              <div id="social-widget" className="widget social">
                <div className="social_widget min-h-[42px]" data-position="widget">
                  <div id="social_wDiv"></div>
                  <div className="flex norm_row social_wDiv">
                    <div className="social_wicons shuffeldiv mr-1">
                      <div className="inerCnt">
                        <a className="trans-ease-all sficn opacity-60 hover:opacity-100" data-effect="fade_in" target="_blank" href="http://www.facebook.com/AEONCorporation" id="sfsiid_facebook">
                          <img data-pin-nopin="true" alt="Facebook" title="Facebook" src="https://dev2.aeonet.com/wp-content/themes/wp-bootstrap-child/inc/assets/img/black_facebook.png" width="36" height="36" className="sfcm sfsi_wicon " data-effect="fade_in" />
                        </a>
                      </div>
                    </div>

                    <div className="social_wicons shuffeldiv mr-1">
                      <div className="inerCnt">
                        <a className="trans-ease-all sficn opacity-60 hover:opacity-100" data-effect="fade_in" target="_blank" href="https://twitter.com/AEONCorp" id="sfsiid_twitter">
                          <img data-pin-nopin="true" alt="Twitter" title="Twitter" src="https://dev2.aeonet.com/wp-content/themes/wp-bootstrap-child/inc/assets/img/black_twitter.png" width="36" height="36" className="sfcm sfsi_wicon " data-effect="fade_in" />
                        </a>
                      </div>
                    </div>

                    <div className="social_wicons shuffeldiv mr-1">
                      <div className="inerCnt">
                        <a className="trans-ease-all sficn opacity-60 hover:opacity-100" data-effect="fade_in" target="_blank" href=" https://www.youtube.com/c/AEONCorporation" id="sfsiid_youtube">
                          <img data-pin-nopin="true" alt="YouTube" title="YouTube" src="https://dev2.aeonet.com/wp-content/themes/wp-bootstrap-child/inc/assets/img/black_youtube.png" width="36" height="36" className="sfcm sfsi_wicon " data-effect="fade_in" />
                        </a>
                      </div>
                    </div>

                    <div className="w-[36px] h-[36px] social_wicons shuffeldiv mr-1">
                      <div className="inerCnt">
                        <a className="trans-ease-all sficn opacity-60 hover:opacity-100" data-effect="fade_in" target="_blank" href="http://www.linkedin.com/company/aeon-corporation" id="sfsiid_linkedin">
                          <img data-pin-nopin="true" alt="LinkedIn" title="LinkedIn" src="https://dev2.aeonet.com/wp-content/themes/wp-bootstrap-child/inc/assets/img/black_linkedin.png" width="36" height="36" className="sfcm sfsi_wicon " data-effect="fade_in" />
                        </a>
                      </div>
                    </div>

                    <div className="social_wicons shuffeldiv mr-1">
                      <div className="inerCnt">
                        <a className="trans-ease-all sficn opacity-60 hover:opacity-100" data-effect="fade_in" target="_blank" href="https://www.instagram.com/aeon_corporation/" id="sfsiid_instagram">
                          <img data-pin-nopin="true" alt="Instagram" title="Instagram" src="https://dev2.aeonet.com/wp-content/themes/wp-bootstrap-child/inc/assets/img/black_instagram.png" width="36" height="36" className="sfcm sfsi_wicon " data-effect="fade_in" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </footer>
  );
}
