import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import MetaHead from "../components/shared/MetaHead";

import TestimonialsFromOurStaffContent from "../components/shared/layouts/TestimonialsFromOurStaffContent";

import usePage from "../contexts/PageContext";
import { gql, useQuery } from "@apollo/client";
import { ADD_PAGES, ADD_LOCATIONS_WITH_CONTENT } from "../lib/graphql";

export default function TestimonialsFromOurStaff(props) {
  const { siteData, setSiteData } = usePage();

  const location = useLocation();

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/") ? location.pathname.split("/").filter((item) => item !== "") : location.pathname;
  const slugId = curLocations[curLocations.length - 1];

  let curQueries = ADD_PAGES;
  if (!siteData?.locations || !siteData.locations.length > 0) curQueries = curQueries + ADD_LOCATIONS_WITH_CONTENT;
  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;

  const {
    loading,
    error,
    data: pageData,
  } = useQuery(GET_PAGE_DATA, {
    variables: { id: slugId },
    skip: siteData?.pages[curLocation] && siteData?.locations && siteData.locations.length > 0,
  });

  //const [ curHash, setCurHash ] = useState(location.hash);
  // const [ selectedLocation, setSelectedLocation ] = useState();

  // useEffect(() => { //TODO: this was moved into the testimonials page, but probably should be here was not working though
  //   console.log("location.hash", location.hash, siteData.locations);
  //   if (siteData.locations.length > 0) {
  //     const newLocation = siteData.locations.find((el) => el.node.slug === location.hash.replace("#", ""));
  //     console.log("newLocation", newLocation);

  //     setSelectedLocation(newLocation || null);
  //   }
  // }, [ location.hash, siteData.locations ]);

  // useEffect(() => {
  //   console.log("selectedLocation", selectedLocation);
  // }, [ selectedLocation ]);

  useEffect(() => {
    console.log("pageData in parent", pageData);

    if (pageData && pageData.pages) {
      let newSiteData = { ...siteData };
      let newPage = pageData.pages?.nodes[0] || [];
      const featuredImage = newPage?.featuredImage?.node || null;
      let locations = pageData?.locations?.edges || [];

      if (featuredImage?.mediaItemUrl) newSiteData.curPanelBackground = featuredImage.mediaItemUrl;

      newSiteData.locations = locations;
      newSiteData.pages = {
        ...siteData.pages,
        [slugId]: { ...newPage },
      };

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: location.pathname,
        curLocations: curLocations,
        curChildren: [],
        curSlug: slugId,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage = siteData.pages[slugId];

  useEffect(() => {
    if (curPage) {
      console.log("setting siteData in parent", curPage.slug);
      // if (curPage && !curPage?.seo) {
      //   console.log("missing SEO!!! curPage", curPage);
      // } else {
      //   console.log("found SEO:", curPage.seo);
      // }
      setSiteData({
        ...siteData,
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curSlug: slugId,
      });
    }
  }, [ curPage ]);

  if (!siteData) return null;

  return (
    <div className="background-blue-gradient w-full h-full">
      <div className="absolute top-0 background-blue-gradient w-full h-full">
        <div id="inner-wrapper" data-speed="100" className="absolute w-full h-full top-0" style={{ background: "url('/images/grid-blue.png') center center repeat fixed" }} />
        <div id="map-wrapper" data-speed="100" className="hidden md:block absolute w-full h-full min-h-[832px]" style={{ background: `url('https://dev2.aeonet.com/wp-content/themes/wp-bootstrap-child/inc/assets/img/map-japan-greena3.png') 50% 124px no-repeat` }} />
      </div>
      <div className="page-container z-10 p-4 relative bg-transparent">
        {curPage && curPage?.seo && <MetaHead seoObject={curPage} />}
        <main id="main-container" className="max-w-[768px] lg:max-w-[1024px] xl:max-w-[1200px] min-[1600px]:max-w-[1400px] m-auto mt-[78px] mb-[60px]">
          {siteData?.locations && <TestimonialsFromOurStaffContent locations={siteData.locations} />}
        </main>
      </div>
    </div>
  );
}
