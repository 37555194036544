import React, { useEffect, useRef } from "react";
import ScrollableListItem from "./ScrollableListItem";

const ScrollableList = ({ items }) => {
  const listRef = useRef(null);

  const scrollToTop = (idx) => {
    uncheckAllCheckboxes(idx);
    const targetPosition = listRef.current.offsetTop + 100;

    // window.scrollTo({
    //   top: 0,
    //   behavior: 'smooth',
    // });
    const listItem = listRef.current;
    setTimeout(() => {
      listItem.scrollIntoView({ behavior: "smooth", block: "start" });
    }, 10);
  };

  const uncheckAllCheckboxes = (idx) => {
    // Get all checkbox elements on the page
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

    // Uncheck each checkbox
    checkboxes.forEach((checkbox) => {
      if (checkbox.id !== `input-${idx}`) checkbox.checked = false;
    });
  };
  const handleItemClick = (idx) => {
    uncheckAllCheckboxes(idx);
    // Calculate the target scroll position
    const targetPosition = listRef.current.offsetTop + 100;
    listRef.current.scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });

    // Perform smooth scrolling to the target position
    window.scrollTo({
      top: 0 + targetPosition * 2,
      behavior: "smooth",
    });
  };

  return (
    <ul id="myList" ref={listRef} className="mt-5 mb-4 transition-all trans-ease">
      {items.map((item, idx) => (
        <ScrollableListItem key={idx} item={item} idx={idx} />
      ))}
    </ul>
  );
};

export default ScrollableList;
