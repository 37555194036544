import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import GoogleMap2 from "../GoogleMap2";

export default function PageContent({ page }) {
  const [ isLoaded, setIsLoaded ] = useState(false);
  const [ newPageContent, setNewPageContent ] = useState();

  const transformImages = (str) => {
    const images = str.match(/<img[^>]*>/g);
    if (images) {
      const newImages = [];

      images.forEach((imageHtml, index) => {
        if (!imageHtml.includes("no-blue")) {
          const isLeft = imageHtml.includes("alignleft");
          const isRight = imageHtml.includes("alignright");
          const isCenter = imageHtml.includes("aligncenter");

          const curSize = isLeft ? "alignleft" : isRight ? "alignright" : "aligncenter";

          const newImage = imageHtml.replace(/<img[^>]*>/g, `<div class='trans-ease-fast blue-bg-wrap ${curSize}'><div class='trans-ease-fast blue-bg'>$&</div></div>`);
          newImages.push({
            old: imageHtml,
            new: newImage,
          });
        }
      });
      newImages.forEach((newImageSet) => {
        str = str.replace(newImageSet.old, newImageSet.new);
      });
    }
    return str;
  };
  useEffect(() => {
    if (newPageContent) {
      var imgs = document.querySelectorAll(".post-content img");
      imgs.forEach.call(imgs, function (element, index, array) {
        const floatDir = element.classList.contains("alignleft") ? "alignleft" : element.classList.contains("alignright") ? "alignright" : "aligncenter";
        element.setAttribute("loading", "lazy");
        setTimeout(function () {
          const parent = element.closest("div");
          if (parent) {
            const grandParent = parent.closest(".blue-bg-wrap");
            if (grandParent) {
              grandParent.classList.add("animated");
              if (floatDir) grandParent.classList.add(floatDir);

              parent.classList.add("animated");
              setTimeout(function () {
                element.classList.add("animated");
              }, 100);
            }
          }
        }, 150);
      });
    }
  }, [ newPageContent ]);

  useEffect(() => {
    if (page && page?.content) {
      const newPageContent = transformImages(page.content);

      setNewPageContent(newPageContent);
      setTimeout(function () {
        setIsLoaded(true);
      }, 100);
    }
  }, [ page ]);

  const StaticArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="#33a1e7" className={`blue w-3 h-3 ml-[1px]`}>
      <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  );

  const HoverArrow = () => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={4} stroke="#33a1e7" className={`trans-ease-all opacity-0 group-hover:opacity-100 w-3 h-3 ml-[-6px]`}>
      <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
    </svg>
  );

  return (
    <article className="inline-block rounded-sm px-4 pt-4 md:px-6 md:pt-8 lg:px-8 lg:pt-10 bg-[#fcfcfc] z-50">
      {page && newPageContent && (
        <>
          <h1 className="font-frutiger-light text-[22px] md:text-[26px] lg:text-[30px] font-extrabold uppercase text-[#24292e]">{page.title}</h1>
          {page?.googleMaps?.latitude && isLoaded && (
            <div className="map_block animated">
              <div id="map_canvas" className="animated">
                <GoogleMap2 latitude={page?.googleMaps?.latitude} longitude={page?.googleMaps?.longitude} zoom={page?.googleMaps?.zoom} mapId={page.slug} />
              </div>
            </div>
          )}
          <div className="post-content mt-2 md:mt-3 xl:mt-4 text-justify" dangerouslySetInnerHTML={{ __html: newPageContent }} />
        </>
      )}
      {page && page?.slug === "career-opportunities" && (
        <div id="career-opportunities-blue" className="mt-1 mt-md-3 mb-4 mt-lg-5 career-opportunities-wrapper">
          <Link className="w-fit mt-2 trans-ease-all arrow-link group flex items-center uppercase text-sm font-semibold  hover:text-[#0056b3]  blue border-transparent hover:border-[#33a1e7] border-b-2" title="Career Opportunities at AEON" to="/career-opportunities">
            <span className="">LEARN MORE</span>
            <StaticArrow />
            <HoverArrow />
          </Link>
        </div>
      )}
    </article>
  );
}
