import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import MetaHead from "../components/shared/MetaHead";
import CorporateRecruitingLocationsContent from "../components/shared/layouts/CorporateRecruitingLocationsContent";
import SideRecruitingLocations from "../components/shared/layouts/SideRecruitingLocations";

import usePage from "../contexts/PageContext";

import { gql, useQuery } from "@apollo/client";
import { ADD_CORPORATE_DIVISON_PAGES_CHILDREN } from "../lib/graphql";

export default function CorporateDivision(props) {
  const { siteData, setSiteData } = usePage();

  const location = useLocation();

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/") ? location.pathname.split("/").filter((item) => item !== "") : location.pathname;
  const slugId = curLocations[curLocations.length - 1];
  const parentSlugId = curLocations[0] || null; // watch out for any with 3 segments

  const isLoading = curLocations[0] !== siteData.curLocations[0];
  //console.log('siteData CorporateDivision', siteData, props)

  let curQueries = ADD_CORPORATE_DIVISON_PAGES_CHILDREN;
  const GET_PAGE_DATA = gql`
  query getPageData($id: Int!) {    
    ${curQueries}
  }
`;

  const {
    loading,
    error,
    data: pageData,
  } = useQuery(GET_PAGE_DATA, {
    variables: { id: 14 },
    skip: siteData?.pages[curLocation],
  });

  useEffect(() => {
    if (pageData && pageData.pages) {
      //console.log("CorporateDivision pageData", pageData);

      let newSiteData = { ...siteData };
      let newPage = pageData.pages?.nodes[0] || [];
      const featuredImage = newPage?.featuredImage?.node || null;
      newSiteData.curPanelBackground = featuredImage?.mediaItemUrl || null;

      newSiteData.pages = {
        ...siteData.pages,
        [slugId]: { ...newPage, children: newPage?.children?.edges || [] },
      };
      console.log("CorporateDivision newSiteData", newSiteData);

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation, //TODO: is this working, old curLocation should be correct?
        curLocation: curLocation,
        curLocations: curLocations,
        curSlug: slugId,
        curChildren: newPage?.children?.edges || [],
        curParent: null,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage = siteData.pages[slugId];

  useEffect(() => {
    if (curPage) {
      let newSiteData = { ...siteData };

      setSiteData({
        ...newSiteData,
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curChildren: curPage?.children?.edges || [],
        curParent: null,
        curSlug: slugId,
        isLoading: false,
      });
    }
  }, [ curPage ]);

  if (!siteData) return null;
  if (curPage && !curPage?.seo) {
    console.log("missing SEO!!! curPage", curPage);
  }
  return (
    <div className="bg-[#eef0f3] w-full h-full">
      <div className="fixed top-0 w-full h-full bg-[#eef0f3]">
        <div id="map-world-wrapper" data-speed="100" className="fixed top-0 w-full h-full" />
      </div>
      <div className="relative z-10 p-4 bg-transparent">
        <main id="main-container" className="min-h-screen max-w-[1600px] m-auto mt-4 mb-[60px] grid grid-cols-4">
          <div id="loading" className="col-span-4 relative w-full flex items-center justify-center top-0 animate-in duration-500 fade-in fade-out ease-in-out trans-ease-all">
            <div className={`${!siteData.isLoading && "h-0 hidden"} relative trans-ease-all`}>
              <div className="absolute animate-in duration-500 fade-in fade-out ease-in-out trans-ease-slow top-[100px] loading"></div>
            </div>
          </div>
          <section className="z-50 col-span-3 px-4 pb-4 mt-4">{curPage && <CorporateRecruitingLocationsContent page={curPage} />}</section>
          <div className="w-full h-full px-4 mt-4 bg-transparent">
            <SideRecruitingLocations sideBarSize="default" page={curPage} parent={siteData.curParent} children={siteData.curChildren} />
          </div>
        </main>
      </div>
    </div>
  );
}
