import React, { useEffect } from "react";
import { useParams, useLocation } from "react-router-dom";

//import MetaHead from "../components/shared/MetaHead";
import RecruitingLocationsContent from "../components/shared/layouts/RecruitingLocationsContent";
import SideRecruitingLocations from "../components/shared/layouts/SideRecruitingLocations";

import usePage from "../contexts/PageContext";

import { gql, useQuery } from "@apollo/client";
import {
  //addPortfolioProjects,
  ADD_PAGES,
  ADD_PAGES_CHILDREN,
} from "../lib/graphql";

export default function RecruitingLocations(props) {
  const { siteData, setSiteData } = usePage();

  const location = useLocation();

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/") ? location.pathname.split("/").filter((item) => item !== "") : location.pathname;
  const slugId = curLocations[curLocations.length - 1].replace("/", "");
  //const parentSlugId = curLocations[0] || null; // watch out for any with 3 segments

  //const isLoading = curLocations[0] !== siteData.curLocations[0];
  //console.log('siteData RecruitingLocations', siteData, props)

  let curQueries = ADD_PAGES_CHILDREN;
  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: { id: slugId },
    skip: siteData?.pages[curLocation],
  });

  useEffect(() => {
    if (pageData && pageData.pages) {
      let newSiteData = { ...siteData };
      let newPage = pageData.pages?.nodes[0] || [];
      const featuredImage = newPage?.featuredImage?.node || null;
      newSiteData.curPanelBackground = featuredImage?.mediaItemUrl || null;

      newSiteData.pages = {
        ...siteData.pages,
        [slugId]: { ...newPage, children: newPage?.children?.edges || [] },
      };

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curSlug: slugId,
        curChildren: newPage?.children?.edges || [],
        curParent: null,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage = siteData.pages[curLocation];

  useEffect(() => {
    if (curPage) {
      let newSiteData = { ...siteData };

      setSiteData({
        ...newSiteData,
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curChildren: curPage?.children?.edges || [],
        curParent: null,
        curSlug: slugId,
        isLoading: false,
      });
    }
  }, [ curPage ]);

  if (!siteData) return null;

  return (
    <div className="bg-[#eef0f3] min-h-screen">
      <div className="fixed top-0 w-full h-full bg-[#eef0f3]">
        <div id="map-world-wrapper" data-speed="100" className="fixed top-0 w-full h-full" />
      </div>
      <main id="main-container" className="trans-ease pt-[12px] lg:pt-[65px] min-h-screen min-[576px]:max-w-[540px] min-[768px]:max-w-[720px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto z-10 mb-[60px] grid grid-cols-4">
        <div id="loading" className="col-span-4 relative w-full flex items-center justify-center top-0 animate-in duration-500 fade-in fade-out ease-in-out trans-ease-all">
          <div className={`${!siteData.isLoading && "h-0 hidden"} relative trans-ease-all`}>
            <div className="absolute animate-in duration-500 fade-in fade-out ease-in-out trans-ease-slow top-[100px] loading"></div>
          </div>
        </div>
        <section className="z-50 col-span-4 lg:col-span-3 mx-auto pb-4 mt-4">{curPage && <RecruitingLocationsContent page={curPage} />}</section>
        <div className="col-span-4 lg:col-span-1 w-full h-full px-4 bg-transparent max-w-[540px] min-[768px]:max-w-[720px] m-auto">
          <SideRecruitingLocations page={curPage} parent={siteData.curParent} children={siteData.curChildren} />
        </div>
      </main>
    </div>
  );
}
