import React, { useState } from "react";
import { TERipple, TEModal, TEModalDialog, TEModalContent, TEModalHeader, TEModalBody, TEModalFooter } from "tw-elements-react";

export default function ModalDefault3({ options, children, title, size = "lg", showModal, setShowModal, onHide, callBack, addClasses = "" }) {
  const animationDirection = {
    top: {
      show: "translate-y-0 opacity-100",
      hidden: "-translate-y-[100%] opacity-0",
    },
    "top-right": {
      show: "translate-x-0 opacity-100",
      hidden: "translate-x-[100%] opacity-0",
    },
    bottom: {
      show: "translate-x-0 opacity-100",
      hidden: "-translate-x-[100%] opacity-0",
    },
  };

  return (
    <TEModal className="right-0" show={showModal} setShow={setShowModal} onHide={() => onHide()}>
      <TEModalDialog position={options.position || "center"} theme={{ ...animationDirection[options.position] }} size={size}>
        <TEModalContent className={addClasses}>
          {title && (
            <TEModalHeader className="border-none p-0">
              <h5 className="text-xl font-medium leading-normal">{title}</h5>
              <button type="button" className="box-content rounded-none border-none hover:no-underline hover:opacity-75 focus:opacity-100 focus:shadow-none focus:outline-none" onClick={() => setShowModal(false)} aria-label="Close">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="h-6 w-6">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </TEModalHeader>
          )}
          <TEModalBody className="text-sm !pt-0 post-content">{children}</TEModalBody>
        </TEModalContent>
      </TEModalDialog>
    </TEModal>
  );
}
