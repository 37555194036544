import React, { useEffect, useState, useRef } from "react";
import { Loader } from "@googlemaps/js-api-loader";

const GoogleMap = ({ latitude, longitude, zoom, mapId }) => {
  const mapRef = useRef(null);
  const [ map, setMap ] = useState(null);
  const [ center, setCenter ] = useState({ lat: Number(latitude), lng: Number(longitude) });

  const updateCoordinates = (newLat, newLng) => {
    setCenter({ lat: Number(newLat), lng: Number(newLng) });
  };

  var main_color = "#33A0D6";
  var styles = [
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [ { hue: "#053454" }, { saturation: 10 }, { lightness: 30 }, { visibility: "simplified" } ],
    },
    {
      featureType: "road",
      elementType: "labels",
      stylers: [ { visibility: "simplified" } ],
    },
    {
      featureType: "administrative",
      elementType: "labels",
      stylers: [ { hue: "#053454" }, { saturation: 10 }, { visibility: "simplified" } ],
    },
  ];

  useEffect(() => {
    const loader = new Loader({
      apiKey: "AIzaSyBN8jwUTB6WxxfHwmHIvBKp-HLPLw-uzQg",
      version: "weekly",
    });

    loader.load().then(() => {
      const google = window.google;

      // Create a new StyledMapType object
      const styledMapType = new google.maps.StyledMapType(styles, { name: "Styled Map" });

      const mapInstance = new google.maps.Map(mapRef.current, {
        center: center,
        zoom: Number(zoom), // reuseMaps: true,
        backgroundColor: main_color,
        mapTypeControl: false,
        streetViewControl: false,
        overviewMapControl: false,
        mapId: mapId,
        reuseMaps: true,
        //mapTypeId: google.maps.MapTypeId.ROADMAP,
        scrollwheel: false,
        mapTypeControlOptions: {
          mapTypeIds: [ "roadmap", "styled_map" ],
        },
      });

      // Associate the styled map with the MapTypeId and set it to display
      mapInstance.mapTypes.set("styled_map", styledMapType);
      mapInstance.setMapTypeId("styled_map");
      setMap(mapInstance);
    });
  }, [ center ]);

  useEffect(() => {
    const handleResize = () => {
      if (map) {
        // Re-center the map after a short delay to ensure the resize is complete
        setTimeout(() => {
          map.setCenter(center);
          map.panTo(center);
        }, 100);
      }
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [ map, center ]);

  useEffect(() => {
    if (map) {
      map.setCenter(center);
    }
  }, [ map, center ]);

  useEffect(() => {
    if (map) updateCoordinates(latitude, longitude);
  }, [ latitude, longitude ]);

  return <div ref={mapRef} style={{ height: "360px", width: "100%" }} />;
};

export default GoogleMap;
