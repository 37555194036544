import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import MetaHead from "../components/shared/MetaHead";

import BackgroundImagePanel from "../components/shared/BackgroundImagePanel";
import ChildrenNavigation from "../components/shared/layouts/ChildrenNavigation";
import PageNavigation from "../components/shared/layouts/PageNavigation";
import PageContent from "../components/shared/layouts/PageContent";

import usePage from "../contexts/PageContext";
import { gql, useQuery } from "@apollo/client";
import { ADD_PAGES, ADD_LOCATIONS_WITH_CONTENT } from "../lib/graphql";

export default function LocationPage(props) {
  const { siteData, setSiteData } = usePage();
  //console.log("siteData props LocationPage", siteData, props);

  const location = useLocation();

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/") ? location.pathname.split("/").filter((item) => item !== "") : location.pathname;
  const slugId = curLocations[curLocations.length - 1].replace("/", "");
  // const parentSlugId = "location"; // watch out for any with 3 segments

  let curQueries = ADD_PAGES;
  if (!siteData?.locations || !siteData.locations.length > 0) curQueries = curQueries + ADD_LOCATIONS_WITH_CONTENT;
  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`; //TODO do we need pages data here?

  const { data: pageData } = useQuery(GET_PAGE_DATA, {
    variables: { id: slugId },
    skip: siteData?.locations && siteData.locations.length > 0,
  });

  useEffect(() => {
    //console.log("has pageData locations", pageData?.locations);

    if (pageData && pageData.locations) {
      let newSiteData = { ...siteData };

      let locations = pageData?.locations?.edges || [];
      if (locations.length > 0) newSiteData.locations = locations;
      const newPage = locations.find((location, idx) => location.node.slug === slugId)?.node;
      const featuredImage = newPage?.featuredImage?.node || null;
      const hasParent = newPage?.staffInformation?.region || null;
      console.log("pageData", pageData);
      if (hasParent && !featuredImage?.mediaItemUrl) {
        const parentFeaturedImage = hasParent?.featuredImage?.node || null;
        if (parentFeaturedImage?.mediaItemUrl) newSiteData.curPanelBackground = parentFeaturedImage.mediaItemUrl;
      } else {
        if (featuredImage?.mediaItemUrl) newSiteData.curPanelBackground = featuredImage.mediaItemUrl;
      }

      let curChildren = locations.filter((location, idx) => location?.node?.staffInformation?.region?.title === hasParent?.title);

      if (!siteData?.locations || !siteData.locations.length > 0) newSiteData.locations = locations;

      newSiteData.pages = {
        ...siteData.pages,
        [curLocation]: { ...newPage },
      };
      const index = curChildren.findIndex((location) => location?.node?.slug === slugId) || 0;
      newSiteData.prevPage = curChildren[index - 1] || null;
      newSiteData.nextPage = curChildren[index + 1] || null;
      //console.log("hasParent index newSiteData.prevPage", hasParent, index, newSiteData.prevPage);
      //console.log("newSiteData.nextPage", newSiteData.nextPage);

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: location.pathname,
        curLocations: curLocations,
        curSlug: slugId,
        curParent: hasParent,
        curChildren: curChildren,
        isLoading: false,
      });
    } else {
      //console.log("does not have pageData so loading it here?");
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  const curPage = siteData?.locations.find((location, idx) => location.node.slug === slugId)?.node || null;

  const parentPage = curPage?.staffInformation?.region || null;
  //console.log("parentPage", parentPage);

  useEffect(() => {
    //console.log("curPage", curPage);
    if (curPage) {
      if (curPage && !curPage?.seo) {
        console.log("missing SEO!!! curPage", curPage);
      } else {
        console.log("found SEO:", curPage.seo);
      }
      // && !pageData
      let newSiteData = { ...siteData };
      // console.log("newSiteData", newSiteData);

      let locations = siteData?.locations || [];

      let newPage = locations.find((location, idx) => location.node.slug === slugId)?.node;

      const featuredImage = newPage?.featuredImage?.node || null;
      const hasParent = newPage?.staffInformation?.region || null;

      //console.log("newPage", newPage);
      const parentSlug = hasParent?.nodes[0]?.slug || null;

      let curChildren = locations.filter((location, idx) => {
        const childSlug = location.node.staffInformation.region.nodes[0].slug || null;
        return childSlug === parentSlug;
      });

      if (hasParent && !featuredImage?.mediaItemUrl) {
        const parentFeaturedImage = hasParent?.featuredImage?.node || hasParent?.nodes[0]?.featuredImage?.node || null;
        if (parentFeaturedImage?.mediaItemUrl) newSiteData.curPanelBackground = parentFeaturedImage.mediaItemUrl;
      } else {
        if (featuredImage?.mediaItemUrl) newSiteData.curPanelBackground = featuredImage.mediaItemUrl;
      }
      const index = curChildren.findIndex((location) => location?.node?.slug === slugId) || 0;
      newSiteData.prevPage = curChildren[index - 1] || null;
      newSiteData.nextPage = curChildren[index + 1] || null;

      setSiteData({
        ...newSiteData,
        curParent: hasParent.nodes[0],
        curChildren: curChildren,
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curSlug: slugId,
      });
    }
  }, [ curPage ]);

  if (!siteData) return null;

  return (
    <div id="location-page" className="bg-[#fcfcfc] min-h-screen">
      {curPage && curPage?.seo && <MetaHead seoObject={curPage} />}

      <BackgroundImagePanel url={siteData.curPanelBackground} />
      <main id="main-container" className="bg-[#fcfcfc] min-h-full w-full z-10 mt-[224px] relative grid grid-cols-4 min-[576px]:max-w-[540px] min-[768px]:max-w-[720px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto mb-4 gap-[1px] trans-ease-all">
        {/* <div className="col-span-4 relative w-full flex items-center justify-center top-0 animate-in duration-500 fade-in fade-out ease-in-out trans-ease-all">
          <div className={`${!siteData.isLoading && "h-0 hidden"} relative trans-ease-all`}>
            <div className="absolute animate-in duration-500 fade-in fade-out ease-in-out trans-ease-slow top-[100px] loading"></div>
          </div>
        </div> */}
        <div className="hidden lg:block -mt-[81px]">
          <PageNavigation size="full" page={curPage} parent={siteData.curParent} children={siteData.curChildren} />
        </div>{" "}
        <section className="col-span-4 min-[1024px]:col-span-3 lg:col-span-3 mx-auto w-full -mt-[81px] blue-arrows">
          <PageContent page={curPage} />
          {curPage && (siteData?.prevPage?.node?.title || siteData?.nextPage?.node?.title) && <ChildrenNavigation parentSlug="location" curPage={curPage} parentPage={parentPage} siteData={siteData} />}
        </section>
      </main>
      <div className="block lg:hidden px-2 max-w-[540px] min-[768px]:max-w-[720px] m-auto">
        <PageNavigation size="full" page={curPage} parent={siteData.curParent} children={siteData.curChildren} />
      </div>
    </div>
  );
}
