import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";

import MetaHead from "../components/shared/MetaHead";
import BackgroundImagePanel from "../components/shared/BackgroundImagePanel";
import PageContent from "../components/shared/layouts/PageContent";

import usePage from "../contexts/PageContext";
import { gql, useQuery } from "@apollo/client";
import {
  //addPortfolioProjects,
  ADD_PAGES,
} from "../lib/graphql";

export default function DefaultPage(props) {
  const { siteData, setSiteData } = usePage();
  //console.log("siteData DefaultPage", siteData, props);

  const location = useLocation();
  //console.log("location DefaultPage", location);

  const curLocations = location.pathname.includes("/") ? location.pathname.split("/").filter((item) => item !== "") : location.pathname;
  const slugId = curLocations[curLocations.length - 1];

  let curQueries = ADD_PAGES;
  const GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;

  const {
    loading,
    error,
    data: pageData,
  } = useQuery(GET_PAGE_DATA, {
    variables: {
      id: slugId,
    },
  });

  useEffect(() => {
    if (pageData && pageData.pages) {
      let newSiteData = { ...siteData };
      let curPage = pageData.pages?.nodes[0] || [];

      if (siteData.pages[slugId]) {
        // console.log('data already exists for slugId do nothing', slugId, siteData.pages[slugId], curPage)
      } else {
        //console.log('curPage data does not exist so setting it now', siteData.pages[slugId], curPage)
        newSiteData.pages = {
          ...siteData.pages,
          [slugId]: {
            ...curPage,
            featuredImage: curPage?.featuredImage?.node || null,
          },
        };
      }
      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: location.pathname,
        curLocations: curLocations,
        curSlug: slugId,
        isLoading: false,
      });
    } else {
      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  if (!siteData) return null;

  const curPage = siteData.pages[siteData.curSlug];
  const featuredImage = curPage?.featuredImage?.mediaItemUrl || null;

  return (
    <>
      {curPage && <MetaHead seoObject={curPage} />}
      <BackgroundImagePanel url={featuredImage} />
      <main id="main-container" className="w-full min-h-screen page-container z-10 mt-[224px] py-4 relative  bg-[#fcfcfc] grid grid-cols-1 page-content max-w-7xl lg:max-w-[1400px] xl:max-w-[1600px] m-auto mb-[60px]">
        <section className="w-full col-span-3 -mt-[110px] blue-arrows">
          <PageContent page={siteData.pages[siteData.curSlug]} />
        </section>
      </main>
    </>
  );
}
