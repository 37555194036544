import React from "react";
import ScrollableList from "../../shared/ScrollableList";

export default function DropdownContent({ page }) {
  //console.log("page DropdownContent", page);
  //const { title, content } = page;
  //const haveCategories = Boolean(categories?.nodes?.length);

  return (
    <article className="p-8 pt-12 bg-[#fcfcfc] z-50 min-h-full">
      {page && (
        <>
          <h1 className="font-frutiger-light text-[30px] font-bold uppercase text-[#24292e]">{page.title}</h1>
          <div className="post-content mt-4 xl:mt-8 float-images-left">{page?.pageContent && <ScrollableList items={page.pageContent} />}</div>
        </>
      )}
    </article>
  );
}
