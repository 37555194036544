export const ADD_SEARCH = `pages(where: {status: PUBLISH, search: $term}, first: 199) {
  nodes {
    excerpt
    title
    slug
  }
`;

export const ADD_SLIDES = `slides(where: {status: PUBLISH}, first: 199) {
  edges {
    node {
      content
      featuredImage {
        node {
          guid
          mediaDetails {
              sizes {
                height
                width
              }
            }
        }
      }
    }
  }
},`;

export const ADD_LOCATIONS = `locations(where: {status: PUBLISH}, first: 199) {
  edges {
    node {
      slug
      title
      staffInformation {
          staffLocation
          staffMessage
          staffTitle
          staffVideo {
            node {
              mediaItemUrl
            }
          }
          staffImage {
            node {
              mediaItemUrl
              sourceUrl(size: THUMBNAIL)
            }
          }
          staffCountry
          xcoords
          ycoords
          regionLinkText
          region {
            nodes {
              ... on Page {
                id
                menuOrder
                title
                slug
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
              }
            }
          }
          locationImage {
            node {
              mediaItemUrl
            }
          }
        }
      excerpt
      slug
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }
},`;
export const ADD_LOCATIONS_WITH_CONTENT = `locations(where: {status: PUBLISH, orderby: {field: MENU_ORDER, order: ASC}}, first: 199) {
  edges {
      node {
        slug
        title
        featuredImage {
        node {
            mediaItemUrl
          }
        }
        staffInformation {          
          staffLocation
          staffMessage
          staffTitle
          staffVideo {
            node {
              mediaItemUrl
            }
          }
          staffImage {
            node {
              mediaItemUrl
              sourceUrl(size: THUMBNAIL)
            }
          }
          staffCountry
          xcoords
          ycoords
          regionLinkText
          region {
            nodes {
              ... on Page {
                id
                menuOrder
                title
                slug
                featuredImage {
                  node {
                    mediaItemUrl
                  }
                }
              }
            }
          }
          locationImage {
            node {
              mediaItemUrl
            }
          }
        }
        content
        excerpt
        seo {
          metaKeywords
          metaDesc
          canonical
          title
          opengraphUrl
          opengraphTitle
          opengraphType
          opengraphAuthor
          opengraphDescription
          opengraphImage {
            mediaItemUrl
            mediaDetails {
              width
              height
            }
            mimeType
          }
          opengraphSiteName
          opengraphUrl
          readingTime
        }
      }
    }
},`;
export const ADD_HOME_REGIONS = `pages(where: {status: PUBLISH, in: [2038, 1731, 1703, 1756, 1792, 1807]}) {
  edges {
    node {
      pageId
      title
      content
      excerpt
    }
  }
},`;

export const ADD_PAGES = `pages(where: {status: PUBLISH, name: $id}) {
  nodes {
    slug    
    content
    googleMaps {
      latitude
      longitude
      zoom
    } 
    seo {
      metaKeywords
      metaDesc
      canonical
      title
      opengraphUrl
      opengraphTitle
      opengraphType
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        mediaItemUrl
        mediaDetails {
          width
          height
        }
        mimeType
      }
      opengraphSiteName
      opengraphUrl
      readingTime
    }
    featuredImage {
      node {        
        mediaDetails {
          sizes {
            height
            width
            sourceUrl
            name
          }
        }        
        srcSet
        sizes        
        mediaItemUrl
      }
    }
    title        
  }
},`;

export const ADD_INTERVIEWS = `pages(where: {status: PUBLISH, name: $id}) {
  nodes {
    slug    
    content                 
  }
},`;

export const ADD_PAGES_CHILDREN = `pages(where: {status: PUBLISH, name: $id}) {
  nodes {
    slug    
    content  
    seo {
      metaKeywords
      metaDesc
      canonical
      title
      opengraphUrl
      opengraphTitle
      opengraphType
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        mediaItemUrl
        mediaDetails {
          width
          height
        }
        mimeType
      }
      opengraphSiteName
      opengraphUrl
      readingTime
    }
    featuredImage {
      node {        
        mediaDetails {
          sizes {
            height
            width
            sourceUrl
            name
          }
        }        
        srcSet
        sizes        
        mediaItemUrl
      }
    }
    title
    children(where: {status: PUBLISH, orderby: {field: MENU_ORDER, order: ASC}}) {
      edges {
        node {
          ... on Page {
            id
            title
            slug
            content
            googleMaps {
              latitude
              longitude
              zoom
            } 
            seo {
              metaKeywords
              metaDesc
              canonical
              title
              opengraphUrl
              opengraphTitle
              opengraphType
              opengraphAuthor
              opengraphDescription
              opengraphImage {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
                mimeType
              }
              opengraphSiteName
              opengraphUrl
              readingTime
            }
            featuredImage {
            node {
              mediaItemUrl
              mediaDetails {
                sizes {
                  file
                }
              }
            }
          }
          }
          slug
        }
      }
    }
  }
},`;

export const ADD_PAGES_PLAIN_CHILDREN = `pages(where: {status: PUBLISH, name: $id}) {
  nodes {
    slug    
    content  
    seo {
      metaKeywords
      metaDesc
      canonical
      title
      opengraphUrl
      opengraphTitle
      opengraphType
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        mediaItemUrl
        mediaDetails {
          width
          height
        }
        mimeType
      }
      opengraphSiteName
      opengraphUrl
      readingTime
    }
    featuredImage {
      node {        
        mediaDetails {
          sizes {
            height
            width
            sourceUrl
            name
          }
        }        
        srcSet
        sizes        
        mediaItemUrl
      }
    }
    title
    children(where: {status: PUBLISH, orderby: {field: MENU_ORDER, order: ASC}}) {
      edges {
        node {
          ... on Page {
            id
            title
            slug
            content           
          }
        }
      }
    }
  }
},`;

export const ADD_CORPORATE_DIVISON_PAGES_CHILDREN = `pages(where: {status: PUBLISH, id: $id}) {
  nodes {
    slug    
    content 
    seo {
      metaKeywords
      metaDesc
      canonical
      title
      opengraphUrl
      opengraphTitle
      opengraphType
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        mediaItemUrl
        mediaDetails {
          width
          height
        }
        mimeType
      }
      opengraphSiteName
      opengraphUrl
      readingTime
    }
    featuredImage {
      node {        
        mediaDetails {
          sizes {
            height
            width
            sourceUrl
            name
          }
        }        
        srcSet
        sizes        
        mediaItemUrl
      }
    }
    title
    children(where: {status: PUBLISH, orderby: {field: MENU_ORDER, order: ASC}}) {
      edges {
        node {
          ... on Page {
            id
            title
            slug
            content
            seo {
              metaKeywords
              metaDesc
              canonical
              title
              opengraphUrl
              opengraphTitle
              opengraphType
              opengraphAuthor
              opengraphDescription
              opengraphImage {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
                mimeType
              }
              opengraphSiteName
              opengraphUrl
              readingTime
            }
            featuredImage {
            node {
              mediaItemUrl
              mediaDetails {
                sizes {
                  file
                }
              }
            }
          }
          }
          slug
        }
      }
    }
  }
},`;
export const ADD_PAGES_WITH_CHILDREN = `pages(where: {status: PUBLISH, name: $id}) {
  nodes {
    slug    
    content
    seo {
      metaKeywords
      metaDesc
      canonical
      title
      opengraphUrl
      opengraphTitle
      opengraphType
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        mediaItemUrl
        mediaDetails {
          width
          height
        }
        mimeType
      }
      opengraphSiteName
      opengraphUrl
      readingTime
    }
    featuredImage {
      node {        
        mediaDetails {
          sizes {
            height
            width
            sourceUrl
            name
          }
        }        
        srcSet
        sizes        
        mediaItemUrl
      }
    }
    title
    children(where: {status: PUBLISH, orderby: {field: MENU_ORDER, order: ASC}}) {
      edges {
        node {
          ... on Page {
            id
            title
            slug
            content
            seo {
              metaKeywords
              metaDesc
              canonical
              title
              opengraphUrl
              opengraphTitle
              opengraphType
              opengraphAuthor
              opengraphDescription
              opengraphImage {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
                mimeType
              }
              opengraphSiteName
              opengraphUrl
              readingTime
            }
            featuredImage {
            node {
              mediaItemUrl
              mediaDetails {
                sizes {
                  file
                }
              }
            }
          }
          }
          slug
        }
      }
    }
  }
},`;
export const ADD_PAGES_PARENT_CHILDREN = `pages(where: {status: PUBLISH, name: $id}) {
  nodes {
    slug
    parent {
      node {
        slug
        ... on Page {
          title
          featuredImage {
            node {
              mediaItemUrl
              mediaDetails {
                sizes {
                  file
                }
              }
            }
          }
          children(first: 100, where: {status: PUBLISH, orderby: {field: MENU_ORDER, order: ASC}}) {
            nodes {
              slug
              ... on Page {
                title
                content
                link
                seo {
                  metaKeywords
                  metaDesc
                  canonical
                  title
                  opengraphUrl
                  opengraphTitle
                  opengraphType
                  opengraphAuthor
                  opengraphDescription
                  opengraphImage {
                    mediaItemUrl
                    mediaDetails {
                      width
                      height
                    }
                    mimeType
                  }
                  opengraphSiteName
                  opengraphUrl
                  readingTime
                }
                featuredImage {
                  node {
                    mediaDetails {
                      sizes {
                        height
                        width
                        sourceUrl
                        name
                      }
                    }        
                    srcSet
                    sizes        
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
    }
    content  
    seo {
      metaKeywords
      metaDesc
      canonical
      title
      opengraphUrl
      opengraphTitle
      opengraphType
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        mediaItemUrl
        mediaDetails {
          width
          height
        }
        mimeType
      }
      opengraphSiteName
      opengraphUrl
      readingTime
    }
    featuredImage {
      node {        
        mediaDetails {
          sizes {
            height
            width
            sourceUrl
            name
          }
        }        
        srcSet
        sizes        
        mediaItemUrl
      }
    }
    title
    children(first: 100, where: {status: PUBLISH, orderby: {field: MENU_ORDER, order: ASC}}) {
      edges {
        node {
          ... on Page {
            id
            title
            slug
            content
            seo {
              metaKeywords
              metaDesc
              canonical
              title
              opengraphUrl
              opengraphTitle
              opengraphType
              opengraphAuthor
              opengraphDescription
              opengraphImage {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
                mimeType
              }
              opengraphSiteName
              opengraphUrl
              readingTime
            }
            googleMaps {
              latitude
              longitude
              zoom
            }    
            featuredImage {
            node {
              mediaItemUrl
              mediaDetails {
                sizes {
                  file
                }
              }
            }
          }
          }
          slug
        }
      }
    }
  }
},`;
//  longitude;
//  latitude;
//  zoom;
export const ADD_PAGES_PARENT_CHILDREN2 = `pages(where: {status: PUBLISH, name: $id, parent: $parent}) {
  nodes {
    slug
    parent {
      node {
        slug
        ... on Page {
          title
          featuredImage {
            node {
              mediaItemUrl
              mediaDetails {
                sizes {
                  file
                }
              }
            }
          }
          children(first: 100, where: {status: PUBLISH, orderby: {field: MENU_ORDER, order: ASC}}) {
            nodes {
              slug
              ... on Page {
                title
                content     
                link
                seo {
                  metaKeywords
                  metaDesc
                  canonical
                  title
                  opengraphUrl
                  opengraphTitle
                  opengraphType
                  opengraphAuthor
                  opengraphDescription
                  opengraphImage {
                    mediaItemUrl
                    mediaDetails {
                      width
                      height
                    }
                    mimeType
                  }
                  opengraphSiteName
                  opengraphUrl
                  readingTime
                }
                googleMaps {
                  latitude
                  longitude
                  zoom
                }
                featuredImage {
                  node {
                    mediaDetails {
                      sizes {
                        height
                        width
                        sourceUrl
                        name
                      }
                    }        
                    srcSet
                    sizes        
                    mediaItemUrl
                  }
                }
              }
            }
          }
        }
      }
    }
    content
    googleMaps {
      latitude
      longitude
      zoom
    }
    seo {
      metaKeywords
      metaDesc
      canonical
      title
      opengraphUrl
      opengraphTitle
      opengraphType
      opengraphAuthor
      opengraphDescription
      opengraphImage {
        mediaItemUrl
        mediaDetails {
          width
          height
        }
        mimeType
      }
      opengraphSiteName
      opengraphUrl
      readingTime
    }
    featuredImage {
      node {        
        mediaDetails {
          sizes {
            height
            width
            sourceUrl
            name
          }
        }        
        srcSet
        sizes        
        mediaItemUrl
      }
    }
    title
    children(first: 100, where: {status: PUBLISH, orderby: {field: MENU_ORDER, order: ASC}}) {
      edges {
        node {
          ... on Page {
            id
            title
            slug
            content
            seo {
              metaKeywords
              metaDesc
              canonical
              title
              opengraphUrl
              opengraphTitle
              opengraphType
              opengraphAuthor
              opengraphDescription
              opengraphImage {
                mediaItemUrl
                mediaDetails {
                  width
                  height
                }
                mimeType
              }
              opengraphSiteName
              opengraphUrl
              readingTime
            }            
            featuredImage {
            node {
              mediaItemUrl
              mediaDetails {
                sizes {
                  file
                }
              }
            }
          }
          }
          slug
        }
      }
    }
  }
},`;

export const addPortfolioProjects = (slug) => {
  return `portfoliolist(idType: SLUG, id: "${slug.toString()}") {
    projects(where: {status: PUBLISH}, first: 299) {
      nodes {
        content
        featuredImage {
          node {            
            srcSet            
            title
            uri
            mediaItemUrl
            mediaDetails {
              sizes {
                width
                height
                sourceUrl
              }
            }
          }
        }
        excerpt
        slug
        title
      }
    }
  },`;
};
