import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import CookieConsent from "react-cookie-consent";
import { Link } from "react-router-dom";

import Footer from "./Footer";
import Navigation from "./Navigation";

import usePage from "../../../contexts/PageContext";

export default function MainWrapper({ children }) {
  const { siteData, setSiteData } = usePage();
  const location = useLocation();

  useEffect(() => {
    setSiteData({ ...siteData, isLoading: true });
  }, []);

  useEffect(() => {
    if (!location.hash) {
      window.scrollTo({
        top: 0,
        behavior: "auto",
      });
    }
  }, [ location ]);

  const autoFocus = (element) => element?.focus();

  return (
    <div id="main-wrapper" className="container-fluid mx-auto relative flex flex-col min-h-screen h-full bg-[#fcfcfc]">
      <a className="skip-link screen-reader-text" href="#main-container">
        Skip to content
      </a>
      <Navigation sticky={true} />
      {children}
      <Footer />
      <div className="fixed w-100 bottom-0 left-0 right-0 z-50">
        <CookieConsent
          location="bottom"
          buttonText="I ACCEPT"
          cookieName="AeonCookieAcceptance"
          disableStyles={true}
          containerClasses="!bg-[#0189e0] rounded-t-lg align-self-start !justify-start !items-center flex text-white w-[75%] m-auto"
          contentClasses="!mx-[14px] !my-[8px] leading-tight text-sm"
          buttonWrapperClasses="mr-4"
          buttonClasses="w-[112px] trans-ease-all btn btn-blue !rounded-md !text-white shadow mt-2 !py-1 !px-4 !m-auto text-sm font-semibold"
          expires={365}
        >
          <div className="text-justify text-base/5">
            Notice: This website and its third-party tools use cookies to manage, monitor, and track usage in order to improve our website’s services. If you would like to learn more about the cookies we use or learn how to withdraw your consent to the use of cookies, please refer to our{" "}
            <Link ref={autoFocus} to="/application-requirements/privacy-policy" className="text-blue-900 font-semibold">
              Cookie Policy
            </Link>
            . You consent to use our cookies if you continue to use this site.
          </div>
        </CookieConsent>
      </div>
    </div>
  );
}
