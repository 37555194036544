import React, { useState, createContext, useContext } from "react";

export const themes = {
  light: {
    foreground: "#000000",
    background: "#eeeeee"
  },
  dark: {
    foreground: "#ffffff",
    background: "#222222"
  }
};

const initialSiteData = {
  isLoading: true,
  curSlug: '',
  curLocation: '',
  curLocations: [],
  curChildren: [],
  curParent: [],
  prevLocation: '',
  navigation: {},
  pages: {},
  locations: [],
  prevPage: '',
  nextPage: '',
  panelBackground: '',
  curPanelBackground: ''
}

const initialState = {
  theme: themes.light,
  setTheme: () => { },
  siteData: initialSiteData,
  setSiteData: () => { }
};

const PageContext = createContext(initialState);

export const PageProvider = ({ children }) => {

  const [theme, setTheme] = useState(themes.light);
  const [siteData, setSiteData] = useState(initialSiteData);

  return (
    <PageContext.Provider value={{ theme, setTheme, siteData, setSiteData }}>
      {children}
    </PageContext.Provider>
  );
};

const usePage = () => {
  const context = useContext(PageContext);

  if (context === undefined) {
    throw new Error("useTheme must be used within a PageContext");
  }
  return context;
};

export default usePage;