import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MapPinIcon, CalendarDaysIcon, DocumentTextIcon } from "@heroicons/react/24/solid";

export default function RecruitingLocationsContent({ page }) {
  const [ pageContent, setPageContent ] = useState({
    worldwideInterviews: null,
    worldwideInterviewsItems: null,
  });

  useEffect(() => {
    if (page.children && page.children.length > 0) {
      let newPageContent = { ...pageContent };
      const worldwideInterviews = page.children.find((child) => child.node.slug === "worldwide-interviews")?.node;

      newPageContent.worldwideInterviews = worldwideInterviews?.content.replaceAll("<ul>", "<ul class='flex w-full justify-between py-4 text-left'>") || null;

      setPageContent({ ...newPageContent });
    }
  }, [ page ]);

  if (!page) return null;

  return (
    <article className="px-4">
      <h1 className="font-frutiger-light font-extrabold uppercase text-[#24292e] text-[22px] md:text-[26px] lg:text-[30px]">{page.title}</h1>
      {page?.content && <div className="mt-4 post-content blue-arrows xl:mt-8" dangerouslySetInnerHTML={{ __html: page.content }} />}
      <div className="recruiting-table mb-6 transition-all text-sm sm:text-base">
        <div id="worldwide-block" className="my-4" data-type="main-office">
          <Link to="/online-application-form/?type=main-office" className="block w-full max-w-[400px] m-auto mb-2">
            <button className="btn btn-blue mb-2 py-1 lg:py-2 px-2 lg:px-4 text-base lg:text-xl h-[42px] lg:h-[56px] w-full background-blue rounded-md text-slate-300 hover:text-white font-semibold bg-opacity-80 hover:bg-opacity-100">APPLY NOW</button>
          </Link>
          <h2 className="uppercase font-semibold text-base lg:text-xl mt-4 mb-2">Upcoming Interviews</h2>
          <div className="worldwide-bg">
            <div className="grid w-full grid-cols-3 align-middle row">
              <div className="pl-1 pr-0 pl-sm-2 pl-md-2 col-3 add-location">
                <div className="heading">
                  <MapPinIcon className="hidden sm:inline-block h-7 w-7" />
                  <span className="pl-1 align-middle pl-lg-1 d-table-cell">Interview Location</span>
                </div>
              </div>
              <div className="pl-1 pr-0 align-middle pl-sm-2 pl-md-2 col-3 add-week">
                <div className="align-middle heading d-table-cell">
                  <CalendarDaysIcon className="hidden sm:inline-block h-7 w-7" />
                  <span className="pl-1 align-middle pl-lg-1 d-table-cell">Date of Interview</span>
                </div>
              </div>
              <div className="pl-1 pr-0 pl-sm-2 pl-md-2 col-3 add-deadline">
                <div className="heading">
                  <DocumentTextIcon className="hidden sm:inline-block h-7 w-7" />
                  <span className="pl-1 align-middle pl-lg-1 inline-block">
                    Deadline
                    <span className="mobile-hidden">
                      <br />
                      For Application
                    </span>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {pageContent.worldwideInterviews && <div className="interview-locations" dangerouslySetInnerHTML={{ __html: pageContent.worldwideInterviews }} />}
        </div>
        <Link to="/online-application-form/?type=main-office" className="block h-[42px] lg:h-[56px] w-full max-w-[400px] m-auto mb-1 lg:mb-2">
          <button className="btn btn-blue mb-1 lg:mb-2 py-1 lg:py-2 px-2 lg:px-4 text-base lg:text-xl h-[42px] lg:h-[56px] w-full background-blue rounded-md text-slate-300 hover:text-white font-semibold bg-opacity-80 hover:bg-opacity-100">APPLY NOW</button>
        </Link>
      </div>
    </article>
  );
}
