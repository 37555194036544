import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import usePage from "../../../contexts/PageContext";

export default function SideOnlineApplication({ page, type, mobileTop, mobileBottom }) {
  const [ pageContent, setPageContent ] = useState({
    afterSubmitting: null,
  });

  useEffect(() => {
    if (page && page?.children && page.children.length > 0) {
      let newPageContent = { ...pageContent };
      const sideBarID = type === "corporate-division" ? "to-apply-to-corporate" : "recruiting-offices";

      console.log("page.children", page.children);
      const toApplyToAeon = page.children.find((child) => child.node.slug === sideBarID)?.node;
      const toApplyToAeonArr = toApplyToAeon.content.split(/<div class="widget-wrap">/);

      toApplyToAeonArr.shift();
      toApplyToAeonArr[0] = toApplyToAeonArr[0].replace(/<h5.*?>/gi, '<h5 class="border-b-4 border-[#33b1e7] font-frutiger-roman font-bold uppercase text-[#24292e] text-[1.25rem] mb-4">');

      const newLocations = [];
      if (toApplyToAeonArr[2]) {
        let newRecruiting = toApplyToAeonArr[2].replace("<ul>", "").replace("</ul>", "").replace(/\n|\r/g, "").split("</li>");

        newRecruiting.forEach((item, idx) => {
          const newArr = item.split("</h6>");
          const newTitle = newArr[0].replace(/(<([^>]+)>)/gi, "") || null;
          const newContent = newArr[1] ? newArr[1].replace(/<div.*?>/gi, "").replace(/<\/div>/gi, "") || null : null;

          if (newTitle && newContent) {
            newLocations.push({
              title: newTitle,
              content: newContent,
            });
          }
        });
        newPageContent.toApplyToAeonLocations = newLocations;
      }

      newPageContent.toApplyToAeonIntro = `<div class="widget-wrap">${toApplyToAeonArr[0]}`;
      newPageContent.toApplyToAeonMessage1 = `<div class="widget-wrap">${toApplyToAeonArr[1]}`;
      newPageContent.toApplyToAeonMessage2 = `<div class="widget-wrap">${toApplyToAeonArr[3]}`;

      const toApplyToCorporate = page.children.find((child) => child.node.slug === "to-apply-to-corporate")?.node;

      newPageContent.toApplyToCorporate = toApplyToCorporate.content || null;
      setPageContent({ ...newPageContent });
    }
  }, [ page ]);

  const { siteData, setSiteData } = usePage();
  // console.log('siteData SideRecruiting', siteData)
  //console.log('parent children SideRecruiting', parent, children)
  return (
    <aside className={`h-full w-full pl-[1px] ${!mobileBottom ? "-mt-[81px]" : ""}`}>
      <section className="blue-triangles sticky top-[53px] p-5 pt-8 bg-[#fcfcfc] z-50 min-h-[100px] trans-ease-slow transition-all">
        <div dangerouslySetInnerHTML={{ __html: pageContent.toApplyToAeonIntro }} />
        <div dangerouslySetInnerHTML={{ __html: pageContent.toApplyToAeonMessage1 }} />

        <ul className="mt-4 mb-4">
          {pageContent.toApplyToAeonLocations &&
            pageContent.toApplyToAeonLocations.map((location, idx) => (
              <li key={`location-${idx}`} className="-mt-2">
                <input type="checkbox" id={location.title} className="peer appearance-none" />
                <label htmlFor={location.title}>
                  <h6 className="mt-2 inline blue-title blue uppercase font-bold text-sm" title={location.title} role="button">
                    {location.title}
                  </h6>
                </label>
                <div className="drop-item-content text-sm transition-all trans-ease">
                  <div className="mb-1" dangerouslySetInnerHTML={{ __html: location.content }} />
                </div>
              </li>
            ))}
        </ul>

        <div dangerouslySetInnerHTML={{ __html: pageContent.toApplyToAeonMessage2 }} />
      </section>
    </aside>
  );
}
