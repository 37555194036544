import React from "react";
import { Routes, Route, useParams, useLocation } from "react-router-dom";

import HomePage from "./HomePage";
import SearchPage from "./SearchPage";

export default function HomeLoader(props) {
  let { search } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  let searchParam = search || queryParams.get("s");

  if (searchParam) {
    return <SearchPage searchParam={searchParam} />;
  } else {
    return <HomePage {...props} />;
  }
}
