import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
//import usePage from "../../../contexts/PageContext";
import { Collapse, Dropdown, Ripple, Carousel, initTE } from "tw-elements";
export default function SideRecruitingLocations({ page, parent, children, sideBarSize = "full" }) {
  const [ pageContent, setPageContent ] = useState({
    recruitingLocations: null,
    corporateDivision: null,
  });
  initTE({ Collapse, Dropdown, Ripple, Carousel });

  useEffect(() => {
    if (page && page?.children && page.children.length > 0) {
      let newPageContent = { ...pageContent };

      const recruitingOffices = page?.children ? page.children.find((child) => child.node.slug === "recruiting-offices")?.node : [];

      if (recruitingOffices?.content) {
        const newLocations = [];

        let newRecruiting = recruitingOffices?.content.replace("<ul>", "").replace("</ul>", "").replaceAll("<a title", '<a class="font-bold" title').replace(/\n|\r/g, "").split("</li>");

        newRecruiting.forEach((item, idx) => {
          const newArr = item.split("</h6>");
          const newTitle = newArr[0].replace(/(<([^>]+)>)/gi, "") || null;
          const newContent = newArr[1] ? newArr[1].replace(/<div.*?>/gi, "").replace(/<\/div>/gi, "") || null : null;

          if (newTitle && newContent) {
            newLocations.push({
              title: newTitle,
              content: newContent,
            });
          }
        });
        newPageContent.recruitingLocations = newLocations;
      }

      const corporateDivision = page.children.find((child) => child.node.slug === "corporate-division")?.node;

      newPageContent.corporateDivision = corporateDivision?.content || null;
      setPageContent({ ...newPageContent }); // change this to siteDate.pageContent so only builds it once
    }
  }, [ page ]);

  return (
    <aside id="secondary" className="sidebar sticky top-[53px] h-100 pt-2 mb-4 px-1">
      <div id="sidebar" className="transition-all w-100 trans-ease-all">
        <div className="sidebar-wrap w-100 pt-3">
          {page && (
            <ul id="widgets" className="m-auto blue-triangles w-100">
              <li className="m-auto widget-container">
                <h5 className="border-b-[5px] border-[#33b1e7] font-frutiger-roman uppercase text-[#24292e] text-[1.25rem] pb-1">Recruiting Offices</h5>
                <ul className="mt-5 mb-4 transition-all trans-ease">
                  {pageContent.recruitingLocations &&
                    pageContent.recruitingLocations.map((location, idx) => (
                      <li key={`location-${idx}`} className="-mt-4 mb-2">
                        <input type="checkbox" id={location.title} className="peer appearance-none" />
                        <label htmlFor={location.title}>
                          <h6 className="select-none inline blue-title blue uppercase font-bold text-[.85rem] md:text-[.9rem] lg:text-[.95rem]" title={location.title} role="button">
                            {location.title}
                          </h6>
                        </label>
                        <div className="drop-item-content text-sm transition-all trans-ease animate-out fade-out">
                          <div className="mb-1 animate-in slide-in-from-top" dangerouslySetInnerHTML={{ __html: location.content }} />
                        </div>
                      </li>
                    ))}
                </ul>
              </li>
              {sideBarSize !== "default" && (
                <li className="m-auto widget-container">
                  <div className="widget-wrap side-corporate-division">
                    <h5 className="border-b-4 border-[#33b1e7] font-frutiger-roman uppercase text-[#24292e] text-[1.25rem]">Corporate Division</h5>
                  </div>
                  {pageContent.corporateDivision && <div dangerouslySetInnerHTML={{ __html: pageContent.corporateDivision }} />}

                  <div className="widget-wrap w-100">
                    <div className="text-center w-100">
                      <Link className="block w-full font-bold pt-2 pb-2 pl-4 pr-4 m-auto mt-2 text-sm uppercase rounded-md learn-more trans-ease-all btn btn-blue w-100" title="Corporate Division" to="/corporate-division/">
                        <span>Learn More</span>
                      </Link>
                    </div>
                  </div>
                  <div className="widget-wrap w-100">
                    <div className="text-center w-100">
                      <img loading="lazy" className="m-auto" title="recruitment-information" src="https://dev2.aeonet.com/wp-content/uploads/2012/07/recruitment-information.png" alt="" width="209" height="82" />
                    </div>
                    <p>Recruitment Information for Japanese citizens</p>
                  </div>
                  <div className="mb-4 widget-wrap w-100">
                    <div className="text-center w-100">
                      <Link className="block w-full font-bold pt-2 pb-2 pl-4 pr-4 m-auto mt-2 text-sm uppercase rounded-md learn-more trans-ease-all btn btn-blue w-100" title="Recruitment Information" to="/recruitment-information-for-japanese-citizens">
                        <span>Learn More</span>
                      </Link>
                    </div>
                  </div>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </aside>
  );
}
