import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import MetaHead from "../components/shared/MetaHead";

import BackgroundImagePanel from "../components/shared/BackgroundImagePanel";
import ChildrenNavigation from "../components/shared/layouts/ChildrenNavigation";
import PageNavigation from "../components/shared/layouts/PageNavigation";
import PageContent from "../components/shared/layouts/PageContent";

import usePage from "../contexts/PageContext";
import { gql, useQuery } from "@apollo/client";
import { ADD_PAGES, ADD_LOCATIONS_WITH_CONTENT, ADD_PAGES_PARENT_CHILDREN, ADD_PAGES_PARENT_CHILDREN2 } from "../lib/graphql";

export default function SideNavPage(props) {
  const { siteData, setSiteData } = usePage();
  const location = useLocation();

  const curLocation = location.pathname.replace("/", "");
  const curLocations = location.pathname.includes("/") ? location.pathname.split("/").filter((item) => item !== "") : location.pathname;
  const slugId = curLocations[curLocations.length - 1];
  const parentSlugId = curLocations[0].replace("/", "") || null; // watch out for any with 3 segments
  const curParent = curLocations.length > 1 ? siteData.pages[parentSlugId] || null : null;

  let GET_PAGE_DATA;

  if (curParent && curLocations.length < 2) {
    let curQueries = !siteData?.locations?.length ? ADD_PAGES + ADD_LOCATIONS_WITH_CONTENT : ADD_PAGES; //this might use w content

    GET_PAGE_DATA = gql`
  query getPageData($id: String!) {    
    ${curQueries}
  }
`;
  } else if (!curParent && !parentSlugId) {
    let curQueries = !siteData?.locations?.length ? ADD_PAGES_PARENT_CHILDREN + ADD_LOCATIONS_WITH_CONTENT : ADD_PAGES_PARENT_CHILDREN; //this might use w content

    GET_PAGE_DATA = gql`
    query getPageData($id: String!) {    
      ${curQueries}
    }
  `;
  } else {
    let curQueries = !siteData?.locations?.length ? ADD_PAGES_PARENT_CHILDREN2 + ADD_LOCATIONS_WITH_CONTENT : ADD_PAGES_PARENT_CHILDREN2; //this might use w content
    //let curQueries = !siteData?.locations?.length ? ADD_PAGES_PARENT_CHILDREN2 + ADD_LOCATIONS : ADD_PAGES_PARENT_CHILDREN2; //this might use w content
    GET_PAGE_DATA = gql`
    query getPageData($id: String!, $parent: ID!) {    
      ${curQueries}
    }
  `;
  }
  //TODO: add a children loader here same way w skip, then can show page while children are loading
  //TODO: this would be lightning fast page loading instead of how it is now, loading all at once

  const {
    loading,
    error,
    data: pageData,
  } = useQuery(GET_PAGE_DATA, {
    variables: { id: slugId, parent: parentSlugId },
    skip: siteData?.pages[curLocation],
  });
  async function initializeMap(lat, long, zoom) {
    const google = window.google;
    const mapDiv = document.getElementById("map_canvas");

    if (mapDiv) {
      const waitForFinalEvent = (function () {
        var timers = {};

        return function (callback, ms, uniqueId) {
          if (!uniqueId) {
            uniqueId = "Don't call this twice without a uniqueId";
          }
          if (timers[uniqueId]) {
            clearTimeout(timers[uniqueId]);
          }
          timers[uniqueId] = setTimeout(callback, ms);
        };
      })();

      var main_color = "#33A0D6";
      var styles = [
        {
          featureType: "road",
          elementType: "geometry",
          stylers: [ { hue: "#053454" }, { saturation: 10 }, { lightness: 30 }, { visibility: "simplified" } ],
        },
        {
          featureType: "road",
          elementType: "labels",
          stylers: [ { visibility: "simplified" } ],
        },
        {
          featureType: "administrative",
          elementType: "labels",
          stylers: [ { hue: "#053454" }, { saturation: 10 }, { visibility: "simplified" } ],
        },
      ];
      if (google && curPage?.googleMaps?.zoom) {
        var styledMap = new google.maps.StyledMapType(styles, { name: "Styled Map" });
        var latlng = new google.maps.LatLng(curPage?.googleMaps?.latitude, curPage?.googleMaps?.longitude);
        //var latlng = new google.maps.LatLng(curPage.latitude, curPage.longitude);
        var myOptions = {
          zoom: Number(curPage.googleMaps.zoom),
          center: latlng,
          backgroundColor: main_color,
          mapTypeControl: false,
          streetViewControl: false,
          overviewMapControl: false,
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          scrollwheel: false,
        };
        var map = new google.maps.Map(mapDiv, myOptions);
        var center = latlng;
        map.mapTypes.set("map_style", styledMap);
        map.setMapTypeId("map_style");

        setTimeout(function () {
          const canvasMap = document.getElementById("map_canvas");
          const blockMap = document.getElementById("map_canvas");
          //if (canvasMap) document.getElementById("map_canvas").classList.add("animated");
          if (blockMap) document.querySelector(".map_block").classList.add("animated");
        }, 1000);

        window.addEventListener("idle", function () {
          center = latlng;
        });

        window.addEventListener("resize", function () {
          if (google) {
            waitForFinalEvent(
              function () {
                google.maps.event.trigger(map, "resize");
                map.panTo(center);
              },
              500,
              "map"
            );
          }
        });
      }
    }
  }

  useEffect(() => {
    // console.log("has pageData pages", pageData?.pages);
    console.log("1 siteData.curPanelBackground", siteData.curPanelBackground);
    console.log("1 siteData", siteData);
    if (pageData && pageData.pages) {
      //console.log("sidenavpage pageData", pageData.pages);
      if (!siteData.locations.length && pageData?.locations?.length) {
        siteData.locations = pageData.locations;
      }
      let newSiteData = { ...siteData };
      let newPage;

      if (pageData.pages?.nodes?.length > 1 && parentSlugId) {
        newPage = pageData.pages.nodes.filter((page) => page?.parent?.node?.slug === parentSlugId)[0];
      } else {
        newPage = pageData.pages?.nodes[0] || {};
      }

      const featuredImage = newPage?.featuredImage?.node || null;
      const hasParent = curParent || newPage.parent?.node || null;

      if (hasParent && !siteData?.pages[parentSlugId]) {
        newSiteData.pages[parentSlugId] = hasParent;
      }
      let curChildren;

      if (hasParent) {
        curChildren = hasParent?.children?.nodes || [];

        const parentFeaturedImage = hasParent?.featuredImage?.node || null;

        if (parentFeaturedImage?.mediaItemUrl) newSiteData.curPanelBackground = parentFeaturedImage.mediaItemUrl;

        // be careful here some childs have images in locations
        newSiteData.pages = {
          ...siteData.pages,
          [curLocation]: {
            ...hasParent,
            children: curChildren,
            featuredImage: parentFeaturedImage,
          },
        };
        //TODO: maybe set children here or are they next?
      } else {
        curChildren = newPage.children?.edges || [];

        //TODO: now since not a child, next step will set own children
        if (featuredImage?.mediaItemUrl) newSiteData.curPanelBackground = featuredImage.mediaItemUrl;
      }

      let newChildren = {};
      if (curChildren && curChildren.length > 0) {
        curChildren.forEach((child) => {
          newChildren[`${parentSlugId}/${child?.node?.slug || child?.slug}`] = child?.node || child;
        });
      }
      if (featuredImage?.mediaItemUrl) newSiteData.curPanelBackground = featuredImage.mediaItemUrl;

      if (siteData.curChildren !== curChildren) {
        newSiteData.curChildren = curChildren?.nodes || curChildren;
      }

      const newPageData = {
        ...newPage,
        content: newPage.content.replace('<img decoding="async"', '<img decoding="async" loading="lazy"'),
        children: curChildren,
        featuredImage: featuredImage,
      };

      newSiteData.pages = {
        ...siteData.pages,
        ...newChildren,
        [curLocation]: newPageData,
      };
      newSiteData.curParent = hasParent;

      setSiteData({
        ...newSiteData,
        prevLocation: newSiteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        locations: pageData?.locations?.edges || siteData.locations || [],
        curSlug: slugId,
        isLoading: false,
      });
    } else {
      console.log("does not have pageData so loading it here?");

      setSiteData({
        ...siteData,
        isLoading: true,
      });
    }
  }, [ pageData ]);

  let curPage = siteData?.pages[curLocation];

  const parentPage = curPage?.parent?.node || siteData?.pages[parentSlugId] || curPage?.curParent || [];
  const curChildren = parentPage?.children ? parentPage.children?.nodes || parentPage.children : curPage?.children?.nodes || curPage?.children || [];

  useEffect(() => {
    //console.log("curPage", curPage);

    if (curPage) {
      if (curPage && !curPage?.seo) {
        console.log("missing SEO!!! curPage", curPage);
      } else {
        console.log("found SEO:", curPage.seo);
      }
      let newSiteData = { ...siteData };
      // console.log("newSiteData", newSiteData);

      const featuredImage = curPage?.featuredImage || parentPage?.featuredImage || null;
      if (featuredImage?.mediaItemUrl) newSiteData.curPanelBackground = featuredImage.mediaItemUrl;

      newSiteData.curChildren = curChildren;
      newSiteData.prevPage = null;
      newSiteData.nextPage = curChildren[0]?.node || curChildren[0];

      curChildren.forEach((item, index) => {
        if ((item?.node?.slug || item?.slug) === slugId) {
          const prev = index > 0 ? index - 1 : null;
          const next = index <= curChildren.length ? index + 1 : null;

          if (index === 0) {
            newSiteData.prevPage = siteData?.curParent?.node || curPage?.parent?.node || curPage?.parent || null;
          } else {
            newSiteData.prevPage = curChildren[prev]?.node || curChildren[prev];
          }
          newSiteData.nextPage = curChildren[next]?.node || curChildren[next];
        }
      });

      if (curPage?.slug === parentSlugId) {
        newSiteData.prevPage = curPage;
      }

      if (parentPage && !newSiteData.prevPage) {
        newSiteData.prevPage = parentPage;
      }

      setSiteData({
        ...newSiteData,
        curParent: parentPage || curPage,
        prevLocation: siteData.curLocation,
        curLocation: curLocation,
        curLocations: curLocations,
        curSlug: slugId,
        isLoading: false,
      });
    }
  }, [ curPage ]);

  if (!siteData) return null;

  return (
    <div id="side-nav-page" className="bg-[#fcfcfc] min-h-screen">
      {curPage && curPage?.seo && <MetaHead seoObject={curPage} addMap={!!curPage?.googleMaps?.latitude} />}
      <BackgroundImagePanel url={siteData.curPanelBackground} />
      <main id="main-container" className="trans-ease min-h-full w-full z-10 mt-[197px] md:mt-[208px] lg:mt-[224px] relative grid grid-cols-4 min-[576px]:max-w-[540px] min-[768px]:max-w-[720px] min-[992px]:max-w-[960px] min-[1200px]:max-w-[1140px] min-[1366px]:max-w-[1200px] min-[1600px]:max-w-[1400px] min-[1846px]:max-w-[1600px] m-auto mb-4 gap-[1px] trans-ease-all">
        <div className="side-container hidden lg:block -mt-[81px]">
          <PageNavigation size="full" page={curPage} parent={siteData.curParent} children={siteData.curChildren} />
        </div>
        <section className="col-span-4 min-[1024px]:col-span-3 lg:col-span-3 mx-auto w-full -mt-[81px] blue-arrows">
          <PageContent page={curPage} />
          {curPage && (siteData?.prevPage?.title || siteData?.nextPage?.title) && <ChildrenNavigation curPage={curPage} parentPage={parentPage} siteData={siteData} />}
        </section>
      </main>
      <div className="side-container block lg:hidden px-2 max-w-[540px] min-[768px]:max-w-[720px] m-auto">
        <PageNavigation size="full" page={curPage} parent={siteData.curParent} children={siteData.curChildren} />
      </div>
    </div>
  );
}
